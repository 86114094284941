import React, { useState, useEffect } from 'react';
import "../css/slick.css"
import "../css/slick-theme.css"
import Slider from "react-slick";
import { apiCallingPost } from "../service";
import Pagination from "react-js-pagination";
const LocalPagePodcasts = (props) => {
  const [podcatsList, setPodcatsList] = useState([]);

  useEffect(() => {
    getPodcastsDataFn();
  }, []);
  const getPodcastsDataFn =() => {
    // debugger
    let postData = {
      functionName: "getPodcast",
      podcastId: "",
      isActive: "true",
      offset: 0,
      max: ""
    }
    // console.log("postData---", postData);
    apiCallingPost(postData).then((data) => {
      // console.log("data---", data.data.result.podcastsDetails);
      if (data.data.success === "1") {
        setPodcatsList(data.data.result.podcastsDetails)
      }
    })
   
  }
    var settingsPodcasts = {
      infinite: false,
      speed: 500,
      dots: false,
      swipeToSlide: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
    return (
      podcatsList.length > 0 &&
        <div className="col-md-12 podcasts-wrapper">
          <h2 className="main-head">Podcasts </h2>
          <div className="podcasts-section">
          <Slider {...settingsPodcasts}>
              {podcatsList.map(data => (
                <div className="podcasts-subList" key={data.createdOnMilliSeconds}
                >
                  <img className="podcasts-img img-fluid" width="1400" height="1400"
                  alt="podcast"                
                  src={data.podcastImage}
                  />
                  <h3 className="podcasts-head">{data.podcastTitle}</h3>
                  <a href={data.podcastLink} className='button-theme button-sm'>Listen Now</a>
                </div>
              ))}
              </Slider>
          </div>
        </div>
  );
}

export default LocalPagePodcasts;
