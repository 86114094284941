import React from 'react';
function LocalPageTags(props) {
    return (
      <div className="col-md-10 tags-wrapper">
          <h2 className="main-head">Tags </h2>
          <div className="tags-list">
              <span className="tags-list-sub">{props.siteData[0].stateName}</span>
              <span className="tags-list-sub">{props.siteData[0].cityName}</span>
              <span className="tags-list-sub">Himalaya</span>
              <span className="tags-list-sub">Wellness</span>
          </div>
      </div>
    );
  }
export default LocalPageTags;
