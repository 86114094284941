import React from 'react';
function LocalPageServicesSub() {
    return (
      <div className="col-md-12 service_locator_option">
      <div className="service_positions">
        <div className="service_options">
          <h2 className="service_optionhead">Services Options </h2>
          <ul>
            <li>Same Day Delivery</li>
            <li>In-store Pickup - Online Orders</li>
            <li>In-store Shopping</li>
            <li>In-store Recycling</li>
          </ul>
        </div>
      </div>
        <div className="service_positions">
          <div className="service_options">
            <h2 className="service_optionhead">Payment Options </h2>
            <ul>
              <li>Cash</li>
              <li>UPI</li>
              <li>Debit Card</li>
              <li>Credit Card</li>
            </ul>
          </div>
        </div>
        <div className="service_positions">
          <div className="service_options">
            <h2 className="service_optionhead">Amenities </h2>
            <ul>
              <li>Washroom</li>
              <li>Parking</li>
            </ul>
          </div>
        </div>
        <div className="service_position service_categories_avilable">
          <div className="service_options">
            <h2 className="service_optionhead">Categories Available </h2>
            <ul>
              <li>Baby Care</li>
              <li>Animal Health</li>
              <li>For Moms</li>
              <li>Nutrition</li>
              <li>Personal Care</li>
              <li>Pharmaceuticals</li>
              <li>Wellness</li>
              <li>Home Care</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
export default LocalPageServicesSub;
