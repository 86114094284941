import React, { useEffect, useState } from "react";
import AdminHeader from "./admin_header";
import { apiCallingPost } from "../service"
import { Target } from "react-feather";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadImg from '../images/upload.png'
import ProdDef from '../images/slider-white.jpg'
import AWS from "aws-sdk";
import ImageLoader from "react-load-image";
import ImageLoading from '../images/loading-cms.gif'
var s3;
function AddEditLocation() {
    const [addStateFlag, setAddStateFlag] = useState(false)
    const [addCityFlag, setAddCityFlag] = useState(false)
    const [editFlag, setEditFlag] = useState(false)
    const [stateList, setStateList] = useState([])
    const [stateId, setStateId] = useState(0)
    const [cityList, setCityList] = useState([])
    const [storeCodeSearch, setStoreCodeSearch] = useState("")
    const [storeCode, setStoreCode] = useState("")
    const [businessName, setBusinessName] = useState("")
    const [completeAddress, setCompleteAddress] = useState("")
    const [stateName, setStateName] = useState("")
    const [city, setCity] = useState("")
    const [locality, setLocality] = useState("")
    const [pinCode, setPinCode] = useState("")
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [contactNumber, setContactNumber] = useState("")
    const [hrs, setHrs] = useState("")
    const [GBPlinks, setGBPLinks] = useState("")
    const [ReviewURL, setReviewURL] = useState("")
    const [saveSuccessFlag, setSaveSuccessFlag] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [storeImg, setStoreImg] = useState("")
    const [imageNamePreview, setImageNamePreview] = useState("")
    const [s3BucketMainFolder, setS3BucketMainFolder] = useState("")
    const [accessKey, setAccessKey] = useState("")
    const [secretKey, setSecretKey] = useState("")
    const [s3bucket, setS3bucket] = useState("")
    const [s3Details, setS3Details] = useState("")
    const [imageFullFilePath, setImageFullFilePath] = useState("")
    const [file, setFile] = useState("")
    const [imageFileName, setImageFileName] = useState("")
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (!window.sessionStorage.getItem("user_id")) {
            window.location.href = "/login"
        }
        if (window.sessionStorage.getItem("addFlag")) {
            // setEditFlag(false)
            clearSiteDetails();
            window.sessionStorage.removeItem("store_code")
            setLoading(false);
        }
        if (window.sessionStorage.getItem("store_code")) {
            setStoreCodeSearch(window.sessionStorage.getItem("store_code"))
            setEditFlag(true);
            getSiteData(window.sessionStorage.getItem("store_code"))
        }
        // console.log('storeCodeSearch',storeCodeSearch)
        getState();
    }, [])

    useEffect(() => {
    }, [stateId])

    useEffect(() => {
        getAwsDetails();
    }, [])



    const Preloader = (props) => {
        return ""
    }
    const getAwsDetails = () => {
        let postData = {
            functionName: "awsCredentials"
        };
        apiCallingPost(postData).then((data) => {
            if (data.data.success === "1") {
                var decoded = data.data.result.s3Details;
                window.sessionStorage.setItem('S3BucketMainFolder', decoded.mainFolder);
                window.sessionStorage.setItem('Bucket', decoded.s3bucket);

                AWS.config.update({
                    accessKeyId: decoded.accessKey,
                    secretAccessKey: decoded.secretKey,
                    region: "ap-south-1"
                });
                var bucketParams = {
                    Bucket: decoded.s3bucket
                };
                s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
                setS3Details(s3)
                setS3BucketMainFolder(decoded.mainFolder)
                setAccessKey(decoded.accessKey)
                setSecretKey(decoded.secretKey)
                setS3bucket(decoded.s3bucket)
                // resolve(true);
            }
            else {
                // console.log('error:home:getAwsDetails:', data.errorMessage)
            }
        })
    }

    const saveImageUpload = () => {
        s3Details.upload(
            {
                Key: imageFullFilePath,
                Body: file,
                ACL: "public-read"
            },
            (err, data) => {
                // console.log("err---", err);
                if (err) {
                    toast.error(
                        "There was an error uploading your photo: ",
                        err.message);
                    return null
                }
                else {
                    toast.success("image upload successfully");
                    // setTimeout(() => {
                    //     window.location.reload();
                    //   }, 500);
                }

            }
        )
    }
    const getState = () => {
        let postData = {
            "functionName": "state"
        }
        apiCallingPost(postData).then((data) => {
            if (data.data.success === "1") {
                setStateList(data.data.result.stateData)
                //console.log("state---", data.data.result.stateData);
                getCity(stateId)
            }
        })
    }
    const changeState = (stateNameValue, stateIdNo) => {
        setStateName(stateNameValue)
        setStateId(stateIdNo)
        if ((stateIdNo !== 0) && (stateIdNo !== undefined)) {
            getCity(stateIdNo)
        }
        else {
            setCityList([])
        }

    }
    const getCity = (stateId) => {
        let postData = {
            "functionName": "city",
            "stateId": stateId
        }
        //console.log("getCity---", postData);
        apiCallingPost(postData).then((data) => {
            //console.log("city---", data);
            if (data.data.success === "1") {
                setCityList(data.data.result.cityData)
            }
        })
    }
    const addLocation = () => {
        setEditFlag(false)
        clearSiteDetails();
    }
    const editLocation = () => {
        setEditFlag(true)
        clearSiteDetails();
    }
    const SaveUpdateData = async () => {
        setSaveSuccessFlag(false)

        if ((imageFullFilePath !== "") && (imageFullFilePath !== null) && (imageFullFilePath !== undefined)) {
            await saveImageUpload();
        }
        function validateAndFocus(value, errorMessage, elementId) {
            if (value === "") {
                toast.error(errorMessage);
                document.getElementById(elementId).focus();
                flag = false;
                return false;
            }
            return true;
        }

        let flag = true;
        if (!validateAndFocus(storeCode, "Please enter Store Code.", "storeCode")) {
            return false;
        }
        else if (!validateAndFocus(businessName, "Please enter Business Name.", "businessName")) {
            return false;
        }
        else if (!validateAndFocus(stateName, "Please enter State.", "stateName")) {
            return false;
        }
        else if (!validateAndFocus(city, "Please enter City.", "city")) {
            return false;
        }
        else if (!validateAndFocus(locality, "Please enter Locality.", "locality")) {
            return false;
        }
        else if (!validateAndFocus(completeAddress, "Please enter Complete Address.", "completeAddress")) {
            return false;
        }
        else if (!validateAndFocus(pinCode, "Please enter PinCode.", "pinCode")) {
            return false;
        }
        else if (!validateAndFocus(latitude, "Please enter Latitude.", "latitude")) {
            return false;
        }
        else if (!validateAndFocus(longitude, "Please enter Longitude.", "longitude")) {
            return false;
        }
        else if (!validateAndFocus(contactNumber, "Please enter Contact Number.", "contactNumber")) {
            return false;
        }
        else if (!validateAndFocus(hrs, "Please enter Operational Hours.", "hrs")) {
            return false;
        }
        else if (!validateAndFocus(GBPlinks, "Please enter GBP Links.", "GBPlinks")) {
            return false;
        }
        else if (!validateAndFocus(ReviewURL, "Please enter Review URL.", "ReviewURL")) {
            return false;
        }
        else if (flag === true) {
            let postData = {
                functionName: "saveOrUpdateLocationHimalayaUnicorn",
                storeCode: storeCode,
                businessName: businessName,
                completeAddress: completeAddress,
                locality: locality,
                city: city,
                state: stateName,
                pinCode: pinCode,
                latitude: latitude,
                longitude: longitude,
                contactNumber: contactNumber,
                operationalHours: hrs,
                GBPlinks: GBPlinks,
                ReviewURL: ReviewURL,
                storeStatus: "",
                refCode: "",
                TypeofStore: "",
                accessibility: "",
                NewBusinessId: "",
                Amenities: "",
                isActive: isActive === true ? "true" : "false",
                storeImage: imageFileName

            }
            //console.log(postData);
            apiCallingPost(postData).then((data) => {
                //console.log("data-data.data--", data.data);
                setAddStateFlag(false)
                setAddCityFlag(false)
                setSaveSuccessFlag(true)
                if (data.data.success === "1") {
                    toast.success("Data updated successfully...");
                    getState();
                    //window.location.reload();
                }
                else {
                    toast.error(data.data.errorMessage)
                }
                clearSiteDetails();
            })

        }
    }

    const clearSiteDetails = () => {
        setStoreCode("")
        setBusinessName("")
        setStateName("")
        setCity("")
        setLocality("")
        setCompleteAddress("")
        setPinCode("")
        setLatitude("")
        setLongitude("")
        setContactNumber("")
        setHrs("")
        setGBPLinks("")
        setReviewURL("")
        setImageNamePreview("")
        setIsActive(false)
        setImageFullFilePath("")
        setImageFileName("")
        setFile("")

    }
    const getSiteData = (storeCodeData) => {
        setAddStateFlag(false)
        setAddCityFlag(false)
        const postData = {
            functionName: "getLocationDetailsByStoreCode",
            storeCode: storeCodeData,
            refCode: ""
        }
        //   console.log('storeCodeSearch',postData)
        apiCallingPost(postData).then((data) => {
            if (data.data.success === "1") {
                if (data.data.result.length > 0) {
                    let locality = data.data.result[0].center_head_name.split("_")
                    setStoreCode(data.data.result[0].center_id)
                    setBusinessName(data.data.result[0].center_head_name.split("_")[0])
                    setStateName(data.data.result[0].state_name)
                    setStateId(data.data.result[0].state_id)
                    setCity(data.data.result[0].city_name)
                    setLocality(locality[1])
                    setCompleteAddress(data.data.result[0].center_address1)
                    setPinCode(data.data.result[0].pin_code)
                    setLatitude(data.data.result[0].latitude)
                    setLongitude(data.data.result[0].longitude)
                    setContactNumber(data.data.result[0].center_phone_number)
                    setHrs(data.data.result[0].operational_hours)
                    setGBPLinks(data.data.result[0].gbp_links)
                    setReviewURL(data.data.result[0].review_links)
                    changeState(data.data.result[0].state_name, data.data.result[0].state_id);
                    setIsActive(data.data.result[0].is_active === true ? true : false)
                    setImageNamePreview(data.data.result[0].imageWithPath)
                    setImageFileName(data.data.result[0].image === null ? "" : data.data.result[0].image)
                    setLoading(false);
                }
                else {
                    clearSiteDetails();
                    setLoading(false);
                    toast.error("No data found")
                }
            }
            // console.log("data---", data);
        })
    }
    const storeImgUpload = (e) => {
        const status = { flag: true }
        let storeImg = e.target.files[0];
        if (!storeImg.name.toLowerCase().endsWith(".png")) {
            toast.error("Please upload a PNG format image.")
            status.flag = false
            return;
        }
        if (storeImg.size > 1048576) {
            // 1 MiB for bytes.
            toast.error("File size must be below 1MB!")
            setImageNamePreview("")
            status.flag = false
            return;
        }
        setImageNamePreview(window.URL.createObjectURL(storeImg))
        let storeImgName = storeImg.name;
        let S3BucketMainFolder = window.sessionStorage.getItem('S3BucketMainFolder');
        let fileName = storeCode + '_small.png';
        let fullFilePath = S3BucketMainFolder + "/" + fileName;

        // Set the file and full file path
        let reader = new FileReader();
        reader.readAsDataURL(storeImg);
        reader.onload = function (event) {
            let img = new Image();
            img.src = event.target.result;
            img.onload = function () {
                if (this.width === 428 && this.height === 221) {
                    status.flag = true
                } else {
                    // console.log("file: add-edit-location.jsx: storeImgUpload ~ width, height: ", this.width, this.height)
                    toast.error("Please upload 428 width and 221 height image.")
                    setImageNamePreview(imageNamePreview)
                    status.flag = false
                }
                // console.log("file: add-edit-location.jsx: storeImgUpload ~ status.flag: ", status.flag)
                if (status.flag) {
                    let reader = new File([storeImg], fileName, {
                        type: "image/png",
                        lastModified: Date.now()
                    });
                    setImageFullFilePath(fullFilePath)
                    setFile(reader)
                    setImageFileName(fileName)

                }
            };
        };
    }


    return (
        <div className="dashboard_wrapper">
       {/* { console.log("loading---", loading)} */}
            <ToastContainer />
            <AdminHeader />
            <div className="dash_breadcrumbs">
                <nav aria-label="breadcrumb">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Add / Edit Store</li>
                        </ol>
                    </div>
                </nav>
                <div className="container pb-3">
                    {loading ?
                        <div className="loder-list loder-list-add-edit">
                            <img src={ImageLoading} alt="loader" width="150" height="auto" className="loder-list-img" />
                        </div>
                        :
                        <>
                            <div className="d-flex align-items-center flex-wrap py-3">
                                {editFlag ?
                                    <h1 className="location_header">Edit Store</h1>
                                    :
                                    <h1 className="location_header">Add Store</h1>
                                }
                                <div className="ml-auto">
                                    {editFlag ?
                                        <button type="button" className="btn btn-primary location-bttns ml-3"
                                            onClick={() => addLocation()}
                                        >Add Store</button>
                                        :
                                        <button type="button" className="btn btn-primary  location-bttns ml-3"
                                            onClick={() => editLocation()}
                                        >Edit Store</button>
                                    }
                                </div>
                            </div>
                            {editFlag &&
                                <div className="row my-4">
                                    <div className="col-sm-5 row">
                                        <label className="col-sm-4 col-form-label">Store Code
                                            <span className="text-danger">* : </span>
                                        </label>
                                        <input type="text" className="col-sm-7 form-control"
                                            id="storeCodeSearch"
                                            value={storeCodeSearch}
                                            onChange={(e) => {
                                                setStoreCodeSearch(e.target.value);
                                                sessionStorage.removeItem("store_code");
                                            }} />
                                    </div>
                                    <div className="col-sm-4">
                                        <button className="btn-save ml-3" onClick={() => getSiteData(storeCodeSearch)}>Search </button>
                                    </div>
                                    <div className="col-12">
                                        <hr></hr>
                                    </div>
                                </div>
                            }
                            {loading ?
                                <div className="loder-list loder-list-add-edit">
                                    <img src={ImageLoading} alt="loader" width="150" height="auto" className="loder-list-img" />
                                </div>
                                :
                                <div className="row" key={saveSuccessFlag}>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Store Code
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            readOnly={editFlag === true ? "readOnly" : null}
                                            id="storeCode"
                                            value={storeCode}
                                            onChange={(e) => setStoreCode(e.target.value)} />
                                    </div>
                                    <div className="col-sm-8 mb-2">
                                        <label className="col-form-label">Business Name
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            id="businessName"
                                            value={businessName}
                                            onChange={(e) => setBusinessName(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">State
                                            <span className="text-danger">*</span>
                                        </label>
                                        {!addStateFlag ?
                                            <>
                                            <label htmlFor="stateName" className="sr-only"/>
                                                <select className="form-control"
                                                    id="stateName"
                                                    value={stateName}
                                                    onChange={(e) => {
                                                        changeState(e.target.value, e.target.options[e.target.selectedIndex].getAttribute("data-key"))
                                                        setCity("")
                                                        setLocality("")
                                                        setAddCityFlag(false)
                                                    }}
                                                >
                                                    <option value={""}>State</option>
                                                    {stateList.map((data, index) => (
                                                        <option key={data.stateId} data-key={data.stateId}
                                                            value={data.stateName}>{data.stateName}</option>
                                                    ))}
                                                </select>
                                                <p className="text-info text-right font-bold mb-0">
                                                    <small className=" cursor-pointer"
                                                        onClick={() => {
                                                            setAddStateFlag(true)
                                                        }}>Add New State</small>
                                                </p>
                                            </>
                                            :
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control"
                                                    id="stateName"
                                                    value={stateName}
                                                    onChange={(e) => {
                                                        changeState(e.target.value, "0")
                                                        setAddCityFlag(true)
                                                        setCity("")
                                                        setLocality("")
                                                    }} />
                                                <div className="input-group-append closed-btn"
                                                    onClick={(e) => {
                                                        setAddStateFlag(false)
                                                        setAddCityFlag(false)
                                                    }} >
                                                    <span className="input-group-text px-2" id="basic-addon2">X</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">City
                                            <span className="text-danger">*</span>
                                        </label>
                                        {!addCityFlag ?
                                            <>
                                            <label htmlFor="city" className="sr-only"/>
                                                <select className="form-control"
                                                    id="city"
                                                    value={city}
                                                    onChange={(e) => {
                                                        setCity(e.target.value)
                                                        setLocality("")
                                                    }}>
                                                    <option value={""}>City</option>
                                                    {cityList.map((data) => (
                                                        <option key={data.cityId} value={data.cityName}>{data.cityName}</option>
                                                    ))
                                                    }
                                                </select>
                                                <p className="text-info text-right font-bold mb-0">
                                                    <small className=" cursor-pointer"
                                                        onClick={() => {
                                                            setAddCityFlag(true)
                                                            setCity("")
                                                        }}>Add New City</small>
                                                </p>
                                            </>
                                            :
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control"
                                                    id="city"
                                                    value={city}
                                                    onChange={(e) => {
                                                        setCity(e.target.value)
                                                    }} />
                                                <div className="input-group-append closed-btn"
                                                    onClick={(e) => { setAddCityFlag(false) }} >
                                                    <span className="input-group-text px-2" id="basic-addon2">X</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Locality
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            id="locality"
                                            value={locality}
                                            onChange={(e) => setLocality(e.target.value)} />
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <label className="col-form-label">Complete Address
                                            <span className="text-danger">*</span>
                                        </label>
                                        <textarea className="form-control"
                                            id="CompleteAddress"
                                            value={completeAddress}
                                            onChange={(e) => setCompleteAddress(e.target.value)}>
                                        </textarea>
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Pin Code
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            id="pinCode"
                                            value={pinCode}
                                            onChange={(e) => setPinCode(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Latitude
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            id="latitude"
                                            value={latitude}
                                            onChange={(e) => setLatitude(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Longitude
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            id="longitude"
                                            value={longitude}
                                            onChange={(e) => setLongitude(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Contact Number
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            id="contactNumber"
                                            value={contactNumber}
                                            onChange={(e) => setContactNumber(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Operational Hours
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            id="hrs"
                                            value={hrs}
                                            onChange={(e) => setHrs(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">GBP Links
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            id="GBPlinks"
                                            value={GBPlinks}
                                            onChange={(e) => setGBPLinks(e.target.value)} />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Review link
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" className="form-control"
                                            id="ReviewURL"
                                            value={ReviewURL}
                                            onChange={(e) => setReviewURL(e.target.value)} />
                                    </div>
                                    <div className="col-sm-6 mb-2 mt-4 position-relative">
                                        <div className="row mx-0 align-items-center">
                                            <label className="col-form-label">Store image </label>
                                            <div className="file_upload_inputfile py-0">
                                                <div className="file_upload_btndiv">
                                                    <button className="file_upload_btn" htmlFor="storeImgFileUpload">Browse File</button>
                                                </div>
                                                <input type="file" className="file_upload_input" id="storeImgFileUpload"
                                                    onChange={(e) => storeImgUpload(e)} />
                                            </div>
                                            {(imageNamePreview !== "") && (imageNamePreview !== null) && (imageNamePreview !== undefined) &&
                                                // <img src={imageNamePreview} className="imgPreview ml-4" alt="upload" 
                                                // width={100} height={50}/>

                                                <ImageLoader src={`${imageNamePreview}`} >
                                                    <img alt={"altText"} className="img-fluid ml-3" width={100} />
                                                    <Preloader />
                                                </ImageLoader>
                                            }
                                        </div>
                                        <p className="bannerImageAlert mt-1">
                                            <span className="bannerTextAlert">Please make sure your uploaded media less than 1 MB and 428*221 px photo size</span>
                                        </p>
                                    </div>
                                    {editFlag &&
                                        <div className="col-sm-2 mt-5 text-right">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input"
                                                    id="isActive"
                                                    checked={isActive}
                                                    onChange={(e) => setIsActive(!isActive)}
                                                />
                                                <label className="custom-control-label" htmlFor="isActive">Is Active</label>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-12 text-right mt-4">
                                        {editFlag ?
                                            <button className="btn-save" onClick={() => SaveUpdateData()}>Update</button>
                                            :
                                            <button className="btn-save ml-3" onClick={() => SaveUpdateData()}>Save </button>
                                        }
                                        <button onClick={() => clearSiteDetails()}
                                            type="button"
                                            className=" btn-cancel ml-3"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default AddEditLocation;
