import React, { useState, useEffect } from "react";
import LocatImg from '../images/direction.png'
import phoneImg from '../images/phone.png'
import timeImg from '../images/time.png'
// import Add from '../images/add.png'
import Edit from '../images/edit.png'
// import Delete from '../images/delete.png'
import lock from "../images/lock.png"
import $ from 'jquery'
import { apiCallingPost} from "../service";
import AWS from "aws-sdk";
let roleName;
var s3;
function LocalPageCentreDetail(props) {

  const [imageList, setImageList] = useState([
    {
      imageFullFilePath: null,
      file: null,
      imageFileName1: null,
      banner1Image: null,
    },
    {
      imageFullFilePath: null,
      file: null,
      imageFileName1: null,
      banner1Image: null,
    },
    {
      imageFullFilePath: null,
      file: null,
      imageFileName1: null,
      banner1Image: null,
    },
  ])
  const [centreDetails, setCentreDetails] = useState(props.centreDetails.length > 0 ? props.centreDetails : [])
  //const [imageArray, setImageArray] = useState(props.centreDetails.length > 0 ? props.centreDetails[0].contentDataSubDescription : [])
  // const [imageArray, setImageArray] = useState(JSON.parse(props.centreDetails[0].contentDataSubDescription))
  const [passingType, setpassingType] = useState(false)
  //const [imagePath, setImagePath] = useState(props.centreDetails.length > 0 ? props.centreDetails[0].imagePath : "")
  const [addressImgPreview, setAddressImgPreview] = useState("")
  const [phImgPreview, setPhImgPreview] = useState("")
  const [hrImgPreview, setHrImgPreview] = useState("")
  const [isApprovedEnable, setIsApprovedEnable] = useState(0)
  const [s3BucketMainFolder, setS3BucketMainFolder] = useState("")
  const [accessKey, setAccessKey] = useState("")
  const [secretKey, setSecretKey] = useState("")
  const [s3bucket, setS3bucket] = useState("")
  const [s3Details, setS3Details] = useState("")

  useEffect(() => {
    getAwsDetails();
    //setImageArray(props.centreDetails.length > 0 ? props.centreDetails[0].contentDataSubDescription : []);
    //setImagePath(props.centreDetails.length > 0 ? props.centreDetails[0].imagePath : "")
    roleName = window.sessionStorage.getItem('roleName')
    setIsApprovedEnable(props.centreDetails.filter(sectionObject => sectionObject.contentDataSubIsApproved === "0").length)

  }, [props]);
  
  const getAwsDetails = () => {
    let postData = {
      functionName: "awsCredentials"
    };
    apiCallingPost(postData).then((data) => {
      // console.log("decoded", data)
      if (data.data.success === "1") {
        var decoded = data.data.result.s3Details;
        // console.log("decoded111", decoded)

        AWS.config.update({
          accessKeyId: decoded.accessKey,
          secretAccessKey: decoded.secretKey,
          region: "ap-south-1"
        });
        var bucketParams = {
          Bucket: decoded.s3bucket
        };
        s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
        setS3Details(s3)
        setS3BucketMainFolder(decoded.mainFolder)
        setAccessKey(decoded.accessKey)
        setSecretKey(decoded.secretKey)
        setS3bucket(decoded.s3bucket)
        // resolve(true);
      }
      else {
        // console.log('error:home:getAwsDetails:', data.errorMessage)
      }
    })
    // })
}

  const bannerImageLoad = (e, mode) => {
    e.preventDefault();
    var fileObj = e.target.files[0];
    if (fileObj.size > 2097152) {
      alert("File size must under 2MB!");
      return;
    }
    let str = fileObj.name;
    var last = str.substring(str.lastIndexOf("/") + 1, str.length);
    let S3BucketMainFolder = window.sessionStorage.getItem('S3BucketMainFolder');
    //console.log("S3BucketMainFolder....", S3BucketMainFolder);
    let fileName = "unicorn_" + last;
    //console.log("fileName....", fileName);
    let reader = new File([fileObj], fileName, {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    let fullFilePath = S3BucketMainFolder + "/" + fileName;
    if (mode === "addressImg") {
      imageList[0] = {
        imageFullFilePath: fullFilePath,
        file: reader,
        imageFileName1: fileName,
        banner1Image: window.URL.createObjectURL(e.target.files[0]),
      };
      //console.log("banner0Image= " + imageList[0].banner1Image);
    } else if (mode === "phoneImg") {
      imageList[1] = {
        imageFullFilePath: fullFilePath,
        file: reader,
        imageFileName1: fileName,
        banner1Image: window.URL.createObjectURL(e.target.files[0]),
      };
      // console.log("banner1Image= " + imageList[1].banner1Image);
    } else if (mode === "hrsImg") {
      imageList[2] = {
        imageFullFilePath: fullFilePath,
        file: reader,
        imageFileName1: fileName,
        banner1Image: window.URL.createObjectURL(e.target.files[0]),
      };
      // console.log("banner2Image= " + imageList[2].banner1Image);
    }
    setAddressImgPreview(imageList[0].banner1Image !== null ? imageList[0].banner1Image : props.centreDetails[0].imagePath + props.centreDetails[0].contentDataSubDescription)
    setPhImgPreview(imageList[1].banner1Image !== null ? imageList[1].banner1Image : props.centreDetails[0].image)
    setHrImgPreview(imageList[2].banner1Image !== null ? imageList[2].banner1Image : props.centreDetails[0].imagePath + props.centreDetails[0].text3)
  
  }
  const saveImageUpload = () => {
    //debugger
    for (let i = 0; i < imageList.length; i++) {
      if (
        imageList[i].imageFullFilePath !== "" &&
        imageList[i].imageFullFilePath !== null &&
        imageList[i].imageFullFilePath !== "null"
      ) {
        let imageFullFilePath = imageList[i].imageFullFilePath;
        let file = imageList[i].file;
        // console.log("imageFullFilePath= ", imageFullFilePath);
        // console.log("file= ", file);
        s3Details.upload(
          {
            Key: imageFullFilePath,
            Body: file,
            ACL: "public-read",
          },
          (err, data) => {
            // console.log("err", err);
            if (err) {
              alert("There was an error uploading your photo: ", err.message);
              return null;
            }
          }
        );
      }
    }
  }
  const saveCenterDetails = async (mode) => {
    //debugger
    await saveImageUpload();
    var flag = true;
    var mobile = /^\d{10}$/;
    let mobilenumber = $("#phoneNumberId").val()
    if (!(mobilenumber.match(mobile))) {
      flag = false;
      alert("Enter Valid Mobile Number");
    }
    else if ($("#phoneNumberId").val() === "") {
      flag = false;
      alert("Mobile Number Required");
    }
    else if ($("#addressTextId").val() === "") {
      flag = false;
      alert("Address Required");
    }
    else if ($("#hrsId").val() === "") {
      flag = false;
      alert("Hours Required");
    }
    else if (flag === true) {
      let contentDataId = props.centreDetailsContentDataId;
      // let contentDataSubId = this.props.firstview.contentDataSubId
      let contentDataSubId = props.centreDetailsContentDataSubId
      // let isApproved = this.props.firstview.contentDataSubIsApproved
      let isApproved = props.centreDetails.length > 0 ? props.centreDetails[0].contentDataSubIsApproved : "0"
      let isSaveDraft = mode === "DRAFT" ? "true" : "false";
      // console.log("isSaveDraft=", isSaveDraft);
      // for (let i = 0; i<=this.state.imageList.length; i++){
      // var subDetail = [
      //   {
      //     subImage0:
      //       passingType === true
      //         ? imageList[0].imageFileName1 !== "null" &&
      //           imageList[0].imageFileName1 !== null
      //           ? imageList[0].imageFileName1
      //           : imageArray.length > 0 ? imageArray[0].subImage0 : ""
      //         : imageList[0].imageFileName1 !== "null" &&
      //           imageList[0].imageFileName1 !== null
      //           ? imageList[0].imageFileName1 : "ddd",
      //     text0: $("#addressTextId").val(),
      //   },
      //   {
      //     subImage1:
      //       passingType === true
      //         ? imageList[1].imageFileName1 !== "null" &&
      //           imageList[1].imageFileName1 !== null
      //           ? imageList[1].imageFileName1
      //           : imageArray.length > 0 ? imageArray[1].subImage1 : ""
      //         : imageList[1].imageFileName1 !== "null" &&
      //           imageList[1].imageFileName1 !== null
      //           ? imageList[1].imageFileName1 : "ddd",
      //     text1: $("#phoneNumberId").val(),
      //   },
      //   {
      //     subImage2:
      //       passingType === true
      //         ? imageList[2].imageFileName1 !== "null" &&
      //           imageList[2].imageFileName1 !== null
      //           ? imageList[2].imageFileName1
      //           : imageArray.length > 0 ? imageArray[2].subImage2 : ""
      //         : imageList[2].imageFileName1 !== "null" &&
      //           imageList[2].imageFileName1 !== null
      //           ? imageList[2].imageFileName1 : "ddd",
      //     text2: $("#hrsId").val(),
      //   },
      // ];
      //let SubDescription = subDetail;
      const postData = {
        functionName: "saveContentDataSub",
        contentDataId: contentDataId,
        contentDataSubId: contentDataSubId,
        contentDataSubDescription: (imageList[0].imageFileName1 !== null ? imageList[0].imageFileName1 : props.centreDetails[0].contentDataSubDescription),
        contentDataSubTitle:  $("#addressTextId").val() ,
        siteId: "",
        contentDataSubIsApproved: isApproved,
        image: (imageList[1].imageFileName1 !== null ? imageList[1].imageFileName1 : props.centreDetails[0].imageName),
        userRole: window.sessionStorage.getItem("roleName"),
        oldImage: "",
        isSaveDraft: isSaveDraft,
        contentDataSubSortOrder: "",
        contentDataSubSubtitle: $("#centreHead").val(),
        pageId: "1",
        altText: "",
        h3Tag: "",
        text1: $("#phoneNumberId").val(),
        text2: $("#hrsId").val(),
        text3:(imageList[2].imageFileName1 !== null ? imageList[2].imageFileName1 : props.centreDetails[0].text3)

      };
      // console.log("saveCourse postData ", postData);
      // console.log("passingType1 = ", passingType);
      apiCallingPost(postData).then((data) => {
        if (data.data.success == 1) {
          alert("Saved Successfully");
          cancelCenterDetail();
          props.getData();
        }
      });
    }
  }

  const addCenterDetail = (mode) => {
    $(".locator_left").addClass("editActive");
    $("#addressImgPreview, #phImgPreview, #hrImgPreview").attr("src", '')
    if (mode === "Edit") {
      setpassingType(true)
      $("#centreHead").val(props.centreDetails.length > 0 ? props.centreDetails[0].contentDataSubSubtitle : "");
      $("#addressTextId").val(props.centreDetails.length > 0 ? props.centreDetails[0].contentDataSubTitle : "");
      $("#phoneNumberId").val(props.centreDetails.length > 0 ? props.centreDetails[0].text1.replaceAll(" ", "") : "");
      $("#hrsId").val(props.centreDetails.length > 0 ? props.centreDetails[0].text2 : "");
      $("#addressImgPreview").attr("src", addressImgPreview ? addressImgPreview : props.centreDetails[0].imagePath + props.centreDetails[0].contentDataSubDescription)
      $("#phImgPreview").attr("src", phImgPreview ? phImgPreview : props.centreDetails[0].image)
      $("#hrImgPreview").attr("src", hrImgPreview ? hrImgPreview : props.centreDetails[0].text3)
      setAddressImgPreview(imageList[0].banner1Image !== null ? imageList[0].banner1Image : props.centreDetails[0].imagePath + props.centreDetails[0].contentDataSubDescription)
      setPhImgPreview(imageList[1].banner1Image !== null ? imageList[1].banner1Image : props.centreDetails[0].image)
      setHrImgPreview(imageList[2].banner1Image !== null ? imageList[2].banner1Image : props.centreDetails[0].imagePath + props.centreDetails[0].text3)
    }
  }
  const cancelCenterDetail = () => {
    $(".locator_left").removeClass("editActive");
    setpassingType(false)
  }
  const approveClick = () => {
    if (isApprovedEnable > 5) {
      alert("Sorry! You can approve maximum 5 Events")
    }
    else {
      props.approveClick(props.centreDetailsContentDataId)
    }
  }

  const rejectData = (contentDataSubId) => {
    props.rejectData(contentDataSubId, $("#storeDetailsRejectReason").val())
  }

  const deleteImg = (mode, imageArray, index) => {
    const postData = {
      functionName: "delete",
      contentDataSubId: imageArray.contentDataSubId
    }
    // console.log('postData --------  ', imageArray)
    if (window.confirm("Do you want to delete?")) {
      apiCallingPost(postData).then((data) => {
        // console.log('delete  ', data)
        if (data.data.success == 1) {
          props.getData()
        }
      });
    } else {
      return false;
    }
  }
  return (
    <div className="locator_left">
 {(props.centreDetails[0] && props.centreDetails.length > 0) &&
          <div className="adminIconLeft">
            {roleName === "SuperAdmin" && isApprovedEnable > 0 ?
              <div className="approveButton" onClick={() => approveClick()}>Approve</div>
              : ""}
            {props.centreDetails[0].isSaveDraft === "true" && props.centreDetails[0].isRejected === "false" ?
              <div className="draftSpan"> Draft </div>
              : ""}
            {roleName === 'SuperAdmin' && props.centreDetails[0].contentDataSubIsApproved === '0' ?
              <div className="rejectSection">
                <div className="rejectButton" onClick={() => rejectData(props.centreDetailsContentDataSubId)}> Reject </div>
                <textarea
                  className="rejectComment"
                  placeholder="Enter Reason"
                  id={"storeDetailsRejectReason"}
                ></textarea>
              </div>
              : ""}
            {props.centreDetails[0].isSaveDraft === "true" && props.centreDetails[0].isRejected === "true" && roleName === "Admin" ?
              <div className="rejectSection">
                <div className="rejectButton"> Rejected </div>
                <div className="rejectCommentText">Reason : {props.centreDetails[0].rejectComment}</div>
              </div>
              : ""}
          </div>
        }




      {props.siteData[0] && 
      <h1 className="locator_lefthead">
          {props.siteData[0].centerName.replaceAll('_', ', ')}
          </h1>
      }
      {(props.centreDetails[0] && props.centreDetails.length > 0) &&
        <p className="locator_stores">{props.centreDetails[0].contentDataSubSubtitle}</p>  }
      <ul className="nav locator_listul">
        <li className="locator_listli locator_width">
          {props.centreDetails.length > 0 && props.centreDetails[0].contentDataSubDescription !== "" && 
            <div className="locator_listlileft">
              <img src={props.centreDetails[0].imagePath + props.centreDetails[0].contentDataSubDescription} className="locator_listlileftimg" alt="location"  width="21" height="21"/>
            </div>
          }
          <div className="locator_listliright">
            {props.centreDetails.length > 0 &&
              <p className='locator_listlirightpara'>{props.centreDetails[0].contentDataSubTitle+", "+props.siteData[0].cityName+" - "+props.siteData[0].pin+", "+props.siteData[0].stateName}</p>
            }
          </div>

        </li>
        <li className="locator_listli locator_innerwidth">
          {props.centreDetails.length > 0 && props.centreDetails[0].image !== "" &&
            <div className="locator_listlileft">
              <img src={props.centreDetails[0].image} className="locator_listlileftimg" alt="phone" width="21" height="21" />
            </div>
          }
          <div className="locator_listliright">
            {props.centreDetails.length > 0 &&
              <a href={"tel:" + props.centreDetails[0].text1.replaceAll(" ", "")} className='locator_listlirightpara locator_phone_link'>{props.centreDetails[0].text1.replaceAll(" ", "")}</a>
            }
          </div>
        </li>
        <li className="locator_listli locator_widthlast">
          {props.centreDetails.length > 0 && props.centreDetails[0].text3 !== "" &&
            <div className="locator_listlileft">
              <img src={props.centreDetails[0].imagePath + props.centreDetails[0].text3} className="locator_listlileftimg" alt="time"  width="21" height="21"/>
            </div>
          }
          <div className="locator_listliright">
            {props.centreDetails.length > 0 &&
              <p className='locator_listlirightpara'>{props.centreDetails[0].text2}</p>
            }
          </div>
        </li>
      </ul>

      {(props.centreDetails.length > 0 && window.sessionStorage.getItem('isLogedIn') === "1" ?
        // props.centreDetails.length > 0 ?
        // props.centreDetails.map((objects, Index) => (
        (window.sessionStorage.getItem("roleName") === "SuperAdmin" ||
          window.sessionStorage.getItem("roleName") === "Admin") && (
          <>
            <div className="adminIconMain">
              {((props.centreDetails[0].contentDataSubIsApproved === '1' || props.centreDetails[0].isSaveDraft === "true")) &&
                <span
                  className="editIcon editButton"
                  onClick={(e) => {
                    addCenterDetail("Edit");
                  }}
                >
                  <img src={Edit} alt="Edit" />
                </span>
              }
              {(props.centreDetails[0].contentDataSubIsApproved === '0' && props.centreDetails[0].isSaveDraft === "false" && typeof window.sessionStorage.getItem("roleName") !== "undefined" && window.sessionStorage.getItem("roleName") === "Admin") &&
                <span
                  className="editIcon lockButton" >
                  <img src={lock} alt="Lock" />
                </span>
              }
            </div>
          </>
          //))
        )
        :
        (window.sessionStorage.getItem("roleName") === "SuperAdmin" ||
          window.sessionStorage.getItem("roleName") === "Admin") && (
          <div className="adminIconMain">
            <span
              className="editIcon editButton"
              onClick={(e) => {
                addCenterDetail("Edit");
              }}
            >
              <img src={Edit} alt="Edit" />
            </span>
          </div>
        )
      )}
      <div className="edit-banner-section">
        <div className="">
          <span className="bannerImageAlert">
            <span className="bannerSizeText"><strong>Format:</strong> jpeg or png</span>
            <span className="bannerSizeText"><strong>Size:</strong> less than 1Mb</span>
            <span className="bannerSizeText"><strong>Dimension:</strong> 28*28 px</span>
            <span className="bannerTextAlert">Please make sure your uploaded media is in jpeg or png format and less than 2 MB and 28*28 px photo size</span>
          </span>
        </div>
        <textarea id="centreHead"rows="2" className="editInput" placeholder="Enter Description"></textarea>
        <div className="location_edit_divmain edit_location_store_div">
            <div className="location_edit_div">
              <div className="imageEditListBanner">
                <span className="fileUpload">
                  <input type="file"
                    id="fileUploadAddressImgAdd"
                    onChange={(e) => bannerImageLoad(e, "addressImg")}
                    accept="image/png,image/jpeg" />
                  <label htmlFor="fileUploadAddressImgAdd">Upload media</label>
                </span>
                <span className="bannerSizeTextEdit">Dimension: 28*28 px</span>
                {props.centreDetails.length > 0 && props.centreDetails[0].contentDataSubDescription !== "" ?
                  <img className="imgPreview" id="addressImgPreview"
                    src={addressImgPreview}
                    alt="preview"
                    style={{ width: "20px" }} />
                  : ""
                }
              </div>
              <textarea id="addressTextId" cols="5" className="editInput" placeholder="Enter Text"></textarea>
            </div>
            <div className="location_edit_div">
              <div className="imageEditListBanner">
                <span className="fileUpload">
                  <input type="file"
                    id="fileUploadPhoneImgAdd"
                    onChange={(e) => bannerImageLoad(e, "phoneImg")}
                    accept="image/png,image/jpeg" />
                  <label htmlFor="fileUploadPhoneImgAdd">Upload media</label>
                </span>
                <span className="bannerSizeTextEdit">Dimension: 28*28 px</span>
                {props.centreDetails.length > 0 && props.centreDetails[0].image !== "" ?
                  <img className="imgPreview" id="phImgPreview"
                    src={phImgPreview}
                    alt="preview"
                    style={{ width: "20px" }} />
                  : ""
                }
              </div>
              <input type='text' maxLength={10} id="phoneNumberId" className="editInput" placeholder="Enter Phone Number" />
            </div>
            <div className="location_edit_div">
              <div className="imageEditListBanner">
                <span className="fileUpload">
                  <input type="file"
                    id="fileUploadHrsImgAdd"
                    onChange={(e) => bannerImageLoad(e, "hrsImg")}
                    accept="image/png,image/jpeg" />
                  <label htmlFor="fileUploadHrsImgAdd">Upload media</label>
                </span>
                <span className="bannerSizeTextEdit">Dimension: 28*28 px</span>
                {props.centreDetails.length > 0 && props.centreDetails[0].text3 !== "" ?
                  <img className="imgPreview" id="hrImgPreview"
                    src={hrImgPreview}
                    alt="preview"
                    style={{ width: "20px" }} />
                  : ""
                }
              </div>
              <input type='text' id="hrsId" className="editInput" placeholder="Enter Hrs" />
            </div>
          </div>
          <div className="editPanelButton">
          {roleName === "Admin" &&
            <span className="saveButton saveDraft" onClick={(e) => { saveCenterDetails("DRAFT") }}>Save Draft</span>
          }
            <span className="saveButton" id={"submitBanner"} onClick={(e) => { saveCenterDetails("Save") }}>Submit</span>
            <span className="cancelButton" onClick={(e) => { cancelCenterDetail() }}>Cancel</span>
          </div>
        </div>
    </div>
  );
}

export default LocalPageCentreDetail;
