import React, { useState, useEffect, useRef } from "react";
import mapIcon from "../images/map-icon.png";
import L, { Icon } from "leaflet";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
var greenIcon = new Icon({
	iconUrl: mapIcon,
	iconSize: [24, 41], // size of the icon
	iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
	popupAnchor: [10, -5], // point from which the popup should open relative to the iconAnchor
});
function LandingPageMap(props) {
	const [mapBounds, setMapBounds] = useState([]);
	const [mapCenter, setMapCenter] = useState(null);

	useEffect(() => {
		if (props.siteData.length > 0) {
			const latitudes = props.siteData.filter(coord=>!isNaN(coord.latitude)).map((coord) => Number(coord.latitude));
			const longitudes = props.siteData.filter(coord=>!isNaN(coord.longitude)).map((coord) => Number(coord.longitude));
			const minLat = Math.min(...latitudes);
			const maxLat = Math.max(...latitudes);
			const minLng = Math.min(...longitudes);
			const maxLng = Math.max(...longitudes);
			setMapBounds([
				[minLat, minLng],
				[maxLat, maxLng],
			]);
			const centerLat = (minLat + maxLat) / 2;
			const centerLng = (minLng + maxLng) / 2;
			setMapCenter([centerLat, centerLng]);
		}
	}, [props.siteData]);

	return (
		<>
					<div id="map" style={{ width: "100%", height: "100%", display: "inline-block" }}>
						<MapContainer
							center={[props.siteData[0].latitude ,  props.siteData[0].longitude]}
							zoom={2}
							scrollWheelZoom={true}
							style={{ width: "100%", height: "100%", display: "inline-block" }}>
							<MapComponent coordinates={props.siteData.filter(coord=>!isNaN(coord.latitude)&&!isNaN(coord.longitude))} />
						</MapContainer>
					</div>
		</>
	);
}
export default LandingPageMap;

const MapComponent = ({ coordinates }) => {
	const mapHook = useMap();
	useEffect(() => {
		if (coordinates.length > 0) {
			const bounds = L.latLngBounds(coordinates.map((marker) => [marker.latitude, marker.longitude]));
			mapHook.fitBounds(bounds);
		}
	}, [coordinates, mapHook]);
	return (
		<>
			<TileLayer
				noWrap
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<MarkerClusterGroup chunkedLoading>
				{coordinates.map((location, i) => (
					<Marker key={i} position={[location.latitude, location.longitude]} icon={greenIcon}>
					</Marker>
				))}
			</MarkerClusterGroup>
		</>
	);
};

