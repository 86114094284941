import React from 'react';
import facebookImg from "../images/facebook.png"
import instagramImg from "../images/instagram.png"
import youtubeImg from "../images/youtube.png"
import linkedInImg from "../images/linkedIn.png"

function LocalPageSocialIcons() {
    return (
      <div className="social-media-wrapper">
          <h2 className="main-head">Social Media  </h2>
          <div className="social-media-list">
              <a className="social-media-img" target="_blank" href="https://www.youtube.com/@himalayawellnesscompany4911">
                <img src={youtubeImg} alt="youtube image" width={30} height={30}></img>
              </a>
              <a className="social-media-img" target="_blank" href="https://www.facebook.com/HimalayaWellnes">
                <img src={facebookImg} alt="facebook image" width={30} height={30}></img>
              </a>
              <a className="social-media-img" target="_blank" href="https://www.instagram.com/himalaya.wellness/?hl=en">
                <img src={instagramImg} alt="instagram image" width={30} height={30}></img>
              </a>
              <a className="social-media-img" target="_blank" href="https://www.linkedin.com/company/himalayawellnesscompany/?originalSubdomain=in">
                <img src={linkedInImg} alt="linkedIn image" width={30} height={30}></img>
              </a>
          </div>
      </div>
    );
  }
export default LocalPageSocialIcons;
