import { useState, useRef, useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ViewLocation from "./landing_page_location_list";
import Logo from '../images/logo.png'
// import DownArrow from '../images/downarrow.png'
import HomeBanner from "./landing_page_banner";
import LandingPageMap from "./landing_page_map";
import LandingPageFranchise from "./landing_page-franchise"
import $ from "jquery"
import { apiCallingPost } from "../service";
import BannerDefaultImg from '../images/banner-deafult.jpg'
import arrowDown from '../images/downarrow.png'

function LandingPage() {
  const [listState, setListState] = useState([])
  const [locationData, setLocationData] = useState([])
  const [listCity, setListCity] = useState([])
  const [stateId, setStateId] = useState([])
  const [status, setStatus] = useState(false)
  const [showstateChange, setStatusChange] = useState(false)
  const [reload, setreload] = useState(new Date())
  const [otherCities, setOtherCities] = useState([])
  const [cityId, setCityId] = useState([])
  const [siteList, setSiteList] = useState([])
  const [siteListFlag, setSiteListFlag] = useState(false)
  const [bannerData, setBannerData] = useState([])
  // const [stateId, setListState] = useState([])
  const [popupShow, setPopupShow] = useState(false)
  const ViewLocationRef = useRef();
  const citySelectRef = useRef()
  
  useEffect(() => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    window.sessionStorage.removeItem('user_id');
    window.sessionStorage.removeItem('username');
    window.sessionStorage.removeItem('isLogedIn');
    window.sessionStorage.removeItem('roleName');
    window.sessionStorage.removeItem('S3BucketMainFolder');
    window.sessionStorage.removeItem('Bucket');
    window.sessionStorage.removeItem('siteId');
    window.sessionStorage.removeItem('url');

    let postData = {
      functionName: "state"
    };
    apiCallingPost(postData).then((data) => {
      setListState(data.data.result.stateData)
      setBannerData(data.data.result.bannerData)
      // console.log("stateData------", data.data.result.stateData);
    });
  }, [])
  useEffect(() => {

  }, [popupShow])

  const stateOnChange = (e) => {
    var value = e.target.value;
    let postData = {
      functionName: "city",
      stateId: value
    };
    apiCallingPost(postData).then((data) => {
      citySelectRef.current.value = "0"
      //console.log("apiCallingPost------", data.data);
      setOtherCities(data.data.result.cityData)
      setLocationData(data.data.result.locationData)
      setSiteList([])
      setStateId(value)
      // setStatus(true)
      setSiteListFlag(false)
      setStatusChange(!showstateChange)
      setreload(new Date())
    });

  }

  const cityOnChange = (e) => {
    //console.log("stateId -------", stateId);
    var cityValue = e.target.value;
    setCityId(cityValue)
    window.sessionStorage.removeItem('siteId');
    var stateIds = stateId;

    if (stateIds != "") {

      let postData = {
        functionName: "siteList",
        stateId: stateIds,
        cityId: cityValue
      };
      window.sessionStorage.setItem("stateId", stateIds)
      window.sessionStorage.setItem("cityId", cityValue)
      apiCallingPost(postData).then((data) => {
        if (data.data.success === "1") {
          setSiteList(data.data.result.siteData)
          setSiteListFlag(true)
          // setStatus(false)
          setStatus(true)
          setStatusChange(!showstateChange)
          // setLocationData([])
          setTimeout(() => {
            ViewLocationRef.current.scrollIntoView({ inline: 'center', behavior: 'smooth' });
          }, 500);
        }
        // console.log("map-------", data.data.result.siteData);
      });
    }
  }
  const enquiryPopup = () => {
    setPopupShow(true)
  }

  const enquiryPopupChild = (msg) => {
    setPopupShow(msg)
  };
  
  return (
    <div className="main_wrapper">
      {/* {console.log("bannerData-----v----", bannerData)} */}
      <Header />
      <div className="main_container">
        <div className="main_banner">
          <div className="m-0 gapY_10">
            {bannerData.length === 0 ?
              <div className="main_banner_list">
                <img src={BannerDefaultImg} alt="Banner image" className="img-fluid main_banner_list_img"  width="1200" height="600"></img>
              </div>
              :
              <HomeBanner key={reload} bannerData={bannerData} stateId={stateId} />
            }
          </div>
        </div>
        <div className="store_location">
          <div className="col-md-12 main-wrapper mx-auto">
            <div className={`row m-auto ${!status ? `container` : ''}`}>
              {/* <div className={status === true ? "col-md-6" : "col-md-12"}> */}
              <div className="col-md-6">
                <div className='store_logolocation'>
                  <h1 className='store_span'>Store Locator :</h1>
                </div>
                <div className="store_locationform" >
                  {/* <div className={`store_locationform ${status !== true ? 'store_locationform_list' : ''} `} > */}
                  <div className='store_form'>
                    <label htmlFor="State" className="sr-only"/>
                    <select id='State' className='form_control'
                      onChange={(e) => stateOnChange(e)}
                    >
                      <option value='0'>Select State</option>
                      {listState.map((stateList, i) => (
                        <option value={stateList.stateId} key={i}>{stateList.stateName}</option>
                      ))}
                    </select>
                  </div>

                  <div className='store_form'>
                  <label htmlFor="City" className="sr-only"/>
                    <select id='City' className='form_control'
                      onChange={(e) => cityOnChange(e)} ref={citySelectRef}
                    >
                      <option value='0'>Select City</option>

                      {otherCities.length > 0 &&
                        otherCities.map((cityList, i) => (
                          <option value={cityList.cityId} key={i}>{cityList.cityName}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              {status === false ?
                <div className="col-md-6">
                  <div className="explore-main" >
                    <div className='explore-div'>
                      <h1 className='store_span'>Explore Franchise Opportunities</h1>
                    </div>
                    <div className="store-enquiry" onClick={() => enquiryPopup()}>Franchise Enquiry Form
                      <img src={arrowDown} width="25" height="25" alt="down" className="ml-auto img-enquiry-form" />
                    </div>
                  </div>
                </div>
                :

                <div className="col-md-6 gmap_location_warapper">
                  <div className="gmap_loction_mapdiv">
                    <LandingPageMap
                      key={showstateChange}
                      siteData={siteList.length > 0 ? siteList : locationData}
                      // statusFlag = {status}
                      siteListFlag={siteListFlag}
                    //siteData ={locationData} 
                    />
                  </div>
                </div>

              }
            </div>
            {/* {console.log("siteListFlag = ", siteListFlag)} */}
            <ViewLocation ViewLocationRef={ViewLocationRef} siteList={siteList} siteListFlag={siteListFlag} />

          </div>
        </div>
      </div>
      <Footer />
      <LandingPageFranchise key={popupShow} franchiseClick={enquiryPopupChild} popupShow={popupShow} />
    </div>
  );
}

export default LandingPage;
