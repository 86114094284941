import React, { useEffect, useState } from "react";
import AdminHeader from "./admin_header";
import { apiCallingPost } from "../service"
import "../css/slick.css"
import "../css/slick-theme.css"
import Slider from "react-slick";
import UploadImg from '../images/upload.png'
import sliderImg from '../images/slider.png'
import Add from '../images/add.png'
import Edit from '../images/edit.png'
import Delete from '../images/delete.png'
import $ from 'jquery'
import AWS from "aws-sdk";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var s3;
const imageWidth = 4042;
const imageHeight = 1250;
const maxImageSizeKB = 2; // 2MB in KB

function BannerImageUpload() {
  const [bannerArray, setBannerArray] = useState([])
  const [bannerPath, setBannerPath] = useState([])
  const [imageFileName, setImageFileName] = useState("")
  const [imageFullFilePath, setImageFullFilePath] = useState("")
  const [file, setFile] = useState("")
  const [imageNamePreview, setImageNamePreview] = useState("")
  const [imageList, setimageList] = useState([
    {
      imageFileName: null,
      altText: "",
      bannerText: "",
    }
  ])
  const [passingType, setPassingType] = useState(false)
  const [s3BucketMainFolder, setS3BucketMainFolder] = useState("")
  const [accessKey, setAccessKey] = useState("")
  const [secretKey, setSecretKey] = useState("")
  const [s3bucket, setS3bucket] = useState("")
  const [s3Details, setS3Details] = useState("")
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [image, setImage] = useState(null);
  const [altText, setAltText] = useState("");

  useEffect(() => {
    getAwsDetails();
    getBannerData();
  }, [])

  useEffect(() => {

  }, [imageFileName, bannerArray])

  useEffect(() => {
  }, [imageNamePreview])


  const AddBanner = (Mode, imageList, index) => {
    if (Mode === "Add") {
      $(".banner-add-wrapper").addClass("editActive");
    }
    else if (Mode === "Edit") {
      $(".addButton").addClass("editIcon-not-click");
      $(".main_banner_list" + index).addClass("editActive");
      $(".bannerAltText" + index).val(imageList.altText)
      setAltText(imageList.altText)
      setPassingType(true)
      setImageNamePreview(bannerPath + imageList.imageFileName)

    }
  }
  const cancelBanner = () => {
    $(".banner-add-wrapper").removeClass("editActive");
    $(".main_banner_list").removeClass("editActive");
    $(".addButton").removeClass("editIcon-not-click");
    setAltText("")
    setImageFileName("")
    setImageFullFilePath("")
    setFile("")
    setImageNamePreview("")
    setPassingType(false)
    setimageList([
      {
        imageFileName: null,
        altText: "",
        bannerText: "",
      }
    ])
  }
  const bannerImageLoad = (e) => {
    let flag = true
    let selectedImage = e.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const { width, height } = img;
          setDimensions({ width, height });
          const fileSizeKB = Math.round(selectedImage.size / 1024);
          if (width !== imageWidth || height !== imageHeight) {
            toast.error(`Image dimensions should not exceed ${imageWidth}px in width and ${imageHeight}px in height.`)
            flag = false;
          }
          else if (fileSizeKB < (maxImageSizeKB * 2)) {
            toast.warning(`File size must be below ${maxImageSizeKB}MB`);
            flag = false;
          }
          else {
            setImage(selectedImage)
            let str = selectedImage.name;
            let fileName = 'himalaya_' + new Date().getTime() + str;
            let reader = new File([selectedImage], fileName, {
              type: "image/jpeg",
              lastModified: Date.now()
            });
            let fullFilePath = s3BucketMainFolder + "/" + fileName;
            $("#hidBannerImageName").val(str)
            setImageFullFilePath(fullFilePath)
            setFile(reader)
            setImageFileName(fileName)
            setImageNamePreview(window.URL.createObjectURL(e.target.files[0]))
          }
        };
      };
      reader.readAsDataURL(selectedImage);
    }
  }
  const saveBanner = async (mode, type, imageListArray, index) => {
    var flag = true
    let imageName = imageFileName === "" ? imageListArray.imageFileName : imageFileName;
    if (($("#hidBannerImageName").val() !== "") && ($("#hidBannerImageName").val() !== undefined)) {
      await saveImageUpload();
    }
    if (type === "Add" && passingType === false) {
      if (imageFileName === "") {
        flag = false;
        toast.error("Please upload image.")
        return false;
      }
      else {
        imageListArray = {
          imageFileName: imageName,
          altText: altText,
          bannerText: "",
        }
        bannerArray.push(imageListArray)
        setBannerArray(bannerArray)
      }

    }
    else if ((type === "Edit") && (passingType === true)) {
      let altTextData = "";
      altTextData = document.getElementById("bannerAltText" + index).value
      if ((imageFileName === "") && (altTextData === altText)) {
        flag = false;
        toast.error("No modifications detected in the banner...")
        return false;
      }
      bannerArray[index] = {
        imageFileName: imageName,
        altText: altTextData,
        bannerText: "",
      };
      let bannerArryEdit = [...bannerArray];
      bannerArryEdit.splice(index, 1);
      setBannerArray(bannerArray)
    }

    if (flag === true) {
      $(".submitBanner").prop('disabled', true);
      const postData = {
        functionName: "saveBanner",
        banner: JSON.stringify(bannerArray),
        config_key: "Banner1"
      }
      apiCallingPost(postData).then((data) => {
        if (data.data.success === "1") {
          setTimeout(() => {
            $(".submitBanner").removeAttr("disabled");
            $("#hidBannerImageName").val('')
            toast.success("Banner saved successfully.")
            //getBannerData();
            cancelBanner()
            window.location.reload();
          }, 3000);
        }

      })
    }
  }
  const getBannerData = () => {
    let postaData = {
      functionName: "getBannerData"
    }
    apiCallingPost(postaData).then((data) => {
      setBannerArray(JSON.parse(data.data.result.bannerData[0].banner))
      setBannerPath(data.data.result.bannerData[0].bannerPath)
    })
  }

  const deleteBanner = (imageList, index) => {
    if (window.confirm("Do you want to delete?")) {
      let bannerArrayDelete = bannerArray;
      bannerArrayDelete.splice(index, 1);
      setBannerArray(bannerArrayDelete);
      // console.log("bannerArray =", bannerArray); mode, type, imageList, index
      saveBanner("", "", bannerArray, "");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      return false;
    }

  }
  const getAwsDetails = () => {
    let postData = {
      functionName: "awsCredentials"
    };
    apiCallingPost(postData).then((data) => {
      if (data.data.success === "1") {
        var decoded = data.data.result.s3Details;
        window.sessionStorage.setItem('S3BucketMainFolder', decoded.mainFolder);
        window.sessionStorage.setItem('Bucket', decoded.s3bucket);

        AWS.config.update({
          accessKeyId: decoded.accessKey,
          secretAccessKey: decoded.secretKey,
          region: "ap-south-1"
        });
        var bucketParams = {
          Bucket: decoded.s3bucket
        };
        s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
        setS3Details(s3)
        setS3BucketMainFolder(decoded.mainFolder)
        setAccessKey(decoded.accessKey)
        setSecretKey(decoded.secretKey)
        setS3bucket(decoded.s3bucket)
        // resolve(true);
      }
      else {
        // console.log('error:home:getAwsDetails:', data.errorMessage)
      }
    })
    // })
  }

  const setData = (e, index) => {
    let val = e.target.value;
    var bannerListData = [...bannerArray]
    bannerListData[index].altText = val
    setBannerArray(bannerListData)
  }
  const saveImageUpload = () => {
    s3Details.upload(
      {
        Key: imageFullFilePath,
        Body: file,
        ACL: "public-read"
      },
      (err, data) => {
        if (err) {
          toast.error(
            "There was an error uploading your photo: ",
            err.message);
          return null
        }
        else {
          // toast.success("image upload successfully");
          // setTimeout(() => {
          //   //window.location.reload();
          // }, 500);
        }

      }
    )
  }
  var settingsBanner = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipe: false,
    draggable: false,
    lazyLoad: true,
  };
  return (
    <div className="dashboard_wrapper main_wrapper_cms">
      <ToastContainer />
      <AdminHeader />
      <div className="dash_breadcrumbs">
        <nav aria-label="breadcrumb">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
              <li className="breadcrumb-item active" aria-current="page">Banner Image Upload</li>
            </ol>
          </div>
        </nav>
        <div className="container pb-3 my-5">
          <div className="banner-edit-wrapper">
            <div className="banner-add-wrapper">
              <div className="adminIconMain">
                <span className="editIcon addButton"
                  onClick={(e) => { AddBanner("Add", "", ""); }}>
                  <img src={Add} alt="Add" />
                </span>
              </div>

              <div className="edit-banner-section">
                <div className="imageEditListBanner">
                  <span className="fileUpload">
                    <input type="file" id="fileUploadBannerAdd" accept="image/png,image/jpeg"
                      onChange={(e) => bannerImageLoad(e)}
                    />
                    <label htmlFor="fileUploadBannerAdd">Upload media</label>
                    <input type="hidden" id="hidBannerImageName" />
                    <span className="bannerImageAlert">
                      <span className="bannerSizeText"><strong>Format:</strong> jpeg or png</span>
                      <span className="bannerSizeText"><strong>Size:</strong> less than 1Mb</span>
                      <span className="bannerSizeText"><strong>Dimension:</strong> {imageWidth}*{imageHeight} px</span>
                      <span className="bannerTextAlert">Please make sure your uploaded media is in jpeg or png format and less than 1 MB and {imageWidth}*{imageHeight} px photo size</span>
                    </span>
                  </span>
                  {imageNamePreview !== "" &&
                    <img className={"imgPreview"} id="bannerPreview" src={imageNamePreview} alt="sliderpreview" />
                  }
                </div>
                <div className="col-md-5 mx-auto">
                  <input type="text" className="editInput"
                    value={altText}
                    onChange={(e) => setAltText(e.target.value)}
                    id="bannerAltText" placeholder="Alt Text" />
                </div>
                <div className="editPanelButton">
                  <button className="saveButton submitBanner" id={"submitBanner"}
                    onClick={(e) => { saveBanner("Save", "Add", "") }}
                  >Submit</button>
                  <span className="cancelButton"
                    onClick={(e) => { cancelBanner() }}
                  >Cancel</span>
                </div>
              </div>
            </div>

            <Slider {...settingsBanner}>
              {bannerArray.length > 0 ?
                bannerArray.map((imageList, index) => (
                  <div id={"main_banner_list" + index} key={index} className={"main_banner_list main_banner_list" + index} >
                    <img src={bannerPath + imageList.imageFileName} alt={imageList.altText} className="img-fluid main_banner_list_img"  width="1200" height="600"/>
                    <>
                      {window.sessionStorage.getItem("isLogedIn") === "1" &&
                        <div className="adminIconMain adminIconMainTop">
                          <span
                            className="editIcon editButton"
                            onClick={(e) => {
                              AddBanner("Edit", imageList, index);
                            }}
                          >
                            <img src={Edit} alt="Edit" />
                          </span>
                          <span className="editIcon DeleteIcon"
                            onClick={() => deleteBanner(imageList, index)}
                          >
                            <img src={Delete} alt="Delete" />
                          </span>
                        </div>
                      }

                      <div className="edit-banner-section">
                        <div className="imageEditListBanner">
                          <span className="fileUpload">
                            <input type="file" id={"fileUploadBanner" + index} accept="image/png,image/jpeg" onChange={(e) => bannerImageLoad(e)} />
                            <label htmlFor={"fileUploadBanner" + index}>Upload media</label>
                            <input type="hidden" id="hidBannerImageName" />
                            <span className="bannerImageAlert">
                              <span className="bannerSizeText"><strong>Format:</strong> jpeg or png</span>
                              <span className="bannerSizeText"><strong>Size:</strong> less than 1Mb</span>
                              <span className="bannerSizeText"><strong>Dimension:</strong> {imageWidth}*{imageHeight} px</span>
                              <span className="bannerTextAlert">Please make sure your uploaded media is in jpeg or png format and less than 1 MB and {imageWidth}*{imageHeight} px photo size</span>
                            </span>
                          </span>
                          {/* {imageNamePreview !== "" && 
                        <img className="imgPreview" id={"bannerPreview" + index} src={imageNamePreview} alt="sliderpreview" />
                      } */}
                          <img className="imgPreview" id={"bannerPreview" + index} src={imageNamePreview === "" ? bannerPath + imageList.imageFileName : imageNamePreview} alt="sliderpreview" />
                        </div>
                        <div className="col-md-5 mx-auto">
                          <input type="text" className={"editInput bannerAltText" + index}
                            id={"bannerAltText" + index}
                            value={imageList.altText}
                            onChange={(e) => setData(e, index)}
                            placeholder="Alt Text" />
                        </div>
                        <div className="editPanelButton">
                          <button className="saveButton submitBanner" id={"submitBanner"}
                            onClick={(e) => { saveBanner("Save", "Edit", imageList, index) }}
                          >Submit</button>
                          <span className="cancelButton" onClick={(e) => { cancelBanner() }}>Cancel</span>
                        </div>
                      </div>
                    </>

                  </div>
                ))
                : ""}
            </Slider>
          </div>

        </div>
      </div>
    </div>
  );
}

export default BannerImageUpload;
