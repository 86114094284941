import React, { useEffect, useState } from "react";
import AdminHeader from "./admin_header";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import UploadImg from '../images/upload.png'
import Delete from '../images/delete.png'
import excelFile from "../images/excel/excel-template.xlsx"
import * as XLSX from 'xlsx';
import { apiCallingPost } from "../service"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const excelMime = ["application/vnd.ms-excel", "application/msexcel", "application/x-msexcel",
    "application/x-ms-excel", "application/x-excel", "application/x-dos_ms_excel", "application/xls",
    "application/x-xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]

function FileUpload() {
    const [file1, setFile1] = useState(null)
    const [file2, setFile2] = useState(null)
    const [activepage, setActivepage] = useState(15)
    const [uploadFlag, setUploadFlag] = useState(false)
    const [dataList, setDataList] = useState([])
    const [fileName, setFileName] = useState("")
    const [excelCompareFlag, setExcelCompareFlag] = useState(false)
    const [isLoader, setIsLoader] = useState(1)
    const [successFlag, setsuccessFlag] = useState(false)

    useEffect(() => {
        if (!window.sessionStorage.getItem("user_id")) {
            window.location.href = "/login"
        }
        readFile(excelFile)
    }, [])

    const pageChange = () => {
        setActivepage(activepage)
    }
    const readFile = async (file) => {
        var reader = new FileReader();
        const response = await fetch(file);
        const blob = await response.blob();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const sheetName = readedData.SheetNames[0];
            const sheet = readedData.Sheets[sheetName];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            setFile1(dataParse);
        };
        reader.readAsBinaryString(blob)
    }

    const readExcel = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const readedData = XLSX.read(data, { type: 'binary' });
                const sheetName = readedData.SheetNames[0];
                const sheet = readedData.Sheets[sheetName];
                const columnHeaders = Object.keys(sheet).filter(key => key[0] === 'A'); // Assuming headers are in column A
      
                // Check if each column has data
                const columnsWithData = columnHeaders.filter(header => {
                    const columnValues = sheet[header];
                    return columnValues && columnValues.v !== undefined && columnValues.v !== null;
                });
                if(columnsWithData.length < 2) {
                    toast.error("Please fill minimum one row.")
                    return false;
                }

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                //console.log("dataParse--readExcel--", dataParse);
                resolve(dataParse);
            };
            reader.readAsBinaryString(file);
        });
    };

    const FileUpladChange = (e) => {
        let files = e.target.files[0]
        if (!excelMime.includes(files.type)) {
            alert("Please upload valid excel file")
            return false;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            setIsLoader(0)
            const data = e.target.result;
            const readedData = XLSX.read(data, { type: "array" });
            const sheetName = readedData.SheetNames[0];
            const sheet = readedData.Sheets[sheetName];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(sheet, { raw: false, header: 1 });
            let DataList = [];
            let DataListTemp = [];
            let keys = [];
            keys = dataParse[0];
            let record = {};
            for (let i = 1; i < dataParse.length; i++) {
                let dd = dataParse[i];
                if (dd.length > 0) {
                    for (let j = 0; j < dd.length; j++) {
                        record[keys[j]?.trim().replaceAll(" ", "").replaceAll(".", "").split("/")[0]] =
                            ((dd[j] === undefined) || (dd[j] === "")) ? "" : dd[j]?.toString().trim();

                    }
                    DataList.push(record);
                    record = {};
                }
            }
            setDataList(DataList)
            setFileName(files.name)
            setFile2(files)
            setIsLoader(1)
        };
        reader.readAsArrayBuffer(files)
        // setTimeout(() => {
        //     compareFiles();
        // }, 500);
    }

    useEffect(() => {
        if(file2) {
            compareFiles();
        }
    }, [file2])
    const compareFiles = async () => {
        if (file1 && file2) {
            try {

                const data2 = await readExcel(file2)
                const header1 = file1.length > 0 ? Object.keys(file1[0]) : [];
                const header2 = data2.length > 0 ? Object.keys(data2[0]) : [];
                //console.log("****JSON.stringify(header2)---", JSON.stringify(header2));
                const headersAreEqual = JSON.stringify(header1) === JSON.stringify(header2)
                if (headersAreEqual) {
                    setExcelCompareFlag(true)
                    setUploadFlag(true)
                }
                else {
                    setExcelCompareFlag(false)
                    toast.error("The Excel files are not same.")
                    setTimeout(() => {
                        FileUploadCancel();
                    }, 500);
                    return false
                }

            } catch (error) {
                setExcelCompareFlag(false)
                toast.error("Error reading Excel files")
                setTimeout(() => {
                    FileUploadCancel();
                }, 200);
            }
        }
        else {
            setExcelCompareFlag(false);
            toast.warn("Please select both Excel files..")
            setTimeout(() => {
                FileUploadCancel();
            }, 200);
        }
    }

    
    const onSave = () => { 
        if(dataList.length > 500) {
          toast.error("Please upload up to 500 data records only...")
          return false
        }
        else {
            let flag = true;
            for (let i = 0; i < dataList.length; i++) {
                if (
                    (dataList[i].StoreCode === "") ||
                    (dataList[i].StoreCode === undefined) ||
                    (dataList[i].BusinessName === "") ||
                    (dataList[i].BusinessName === undefined) ||
                    (dataList[i].City === "") ||
                    (dataList[i].City === undefined) ||
                    (dataList[i].CompleteAddress === "") ||
                    (dataList[i].CompleteAddress === undefined) ||
                    (dataList[i].ContactNumber === "") ||
                    (dataList[i].ContactNumber === undefined) ||
                    (dataList[i].GBPLinks === "") ||
                    (dataList[i].GBPLinks === undefined) ||
                    (dataList[i].Latitude === "") ||
                    (dataList[i].Latitude === undefined) ||
                    (dataList[i].Longitude === "") ||
                    (dataList[i].Longitude === undefined) ||
                    (dataList[i].OperationalHours === "") ||
                    (dataList[i].OperationalHours === undefined) ||
                    (dataList[i].PinCode === "") ||
                    (dataList[i].PinCode === undefined) ||
                    (dataList[i].ReviewURL === "") ||
                    (dataList[i].ReviewURL === undefined) ||
                    (dataList[i].State === "") ||
                    (dataList[i].State === undefined) ||
                    (dataList[i].Locality === "") ||
                    (dataList[i].Locality === undefined) 
                ) {
                    flag = false;
                    setTimeout(() => {
                        setDataList([]);
                        setUploadFlag(false);
                    }, 3000);
                }
            }
            if(flag === true) {
                const apiData = {
                    functionName: "himalayaUnicornLocExcelFileUpload",
                    dataSet: dataList
                  };
                  //console.log("apiData--->", apiData)
                  toast.success("The update will take a few minutes....")
                  FileUploadCancel();
                  apiCallingPost(apiData).then((data) => {
                    //   console.log("data--->", data)
                  });
            }
            else {
                toast.error("Please fill all fields in the Excel file.");
            }
        }
      };
    
    const OutTable = () => {
        return (
            <div className="file_upload_table_div">
                <div className="table_fileupload_btn">
                    <button type="button" className="file_upload" onClick={() => onSave()}>Upload</button>
                    <button type="button" className="file_upload_cancel" onClick={() => FileUploadCancel()}>Cancel</button>
                </div>
                <div className="table-responsive">
                    <div className="file_upload_table_responsive">
                        <table className="table table-bordered file_upload_table_tag mb-0">
                            <thead>
                                <tr>
                                    <th>Sl No.</th>
                                    <th>Store Code</th>
                                    <th>Business Name</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Locality</th>
                                    <th style={{minWidth:"200px"}}>Complete Address</th>
                                    <th>Pin Code</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                    <th>Contact Number</th>
                                    <th>Operational Hours</th>
                                    <th>GBP Links</th>
                                    <th>Review URL</th>
                                    <th>Location Page URL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataList.map((data, index) => (
                                <tr key={data.StoreCode}>
                                    <td>{index+1}</td>
                                    <td>{data.StoreCode}</td>
                                    <td>{data.BusinessName}</td>
                                    <td>{data.State}</td>
                                    <td>{data.City}</td>
                                    <td>{data.Locality}</td>
                                    <td>{data.CompleteAddress}</td>
                                    <td>{data.PinCode}</td>
                                    <td>{data.Latitude}</td>
                                    <td>{data.Longitude}</td>
                                    <td>{data.ContactNumber}</td>
                                    <td>{data.OperationalHours}</td>
                                    <td>{data.GBPLinks}</td>
                                    <td>{data.ReviewURL}</td>
                                    <td>{data.LocationPageURL}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {dataList.length > 3 && 
                    <div className="table_fileupload_btn mt-3">
                        <button type="button" className="file_upload" onClick={() => onSave()}>Upload</button>
                        <button type="button" className="file_upload_cancel" onClick={() => FileUploadCancel()}>Cancel</button>
                    </div>
                }
            </div>
        )
    }
    
    const FileUploadCancel = () => {
        setUploadFlag(false)
    }
    
    return (
        <div className="dashboard_wrapper">
            {isLoader === 0 &&
                <div className="loder-wrapper"></div>
            }
            {/* {console.log("*** isLoader-", isLoader)} */}
            <ToastContainer />
            <AdminHeader />
            <div className="dash_breadcrumbs">
                <nav aria-label="breadcrumb">
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Fileupload</li>
                        </ol>
                    </div>
                </nav>
                <div className="container">
                    <div className="heading_wrapper_div d-md-flex justify-content-between">
                        <h1 className="view_reportsheading">File Upload</h1>
                        <a className="btn btn-primary" href={excelFile}>Download Template</a>
                    </div>
                    <div className="file_upload_div">
                        <div className="file_upload_innerdiv">
                            {uploadFlag === true ?
                                OutTable()
                                :
                                <div className="file_upload_inputfile">
                                    <div className="file_upload_divtag">
                                        <img src={UploadImg} className="file_upload_img" alt="upload" />
                                    </div>
                                    <div className="file_drag_drop">
                                        <span className="span_drag_drop">Drag & Drop you file here</span>
                                        <span className="span_drag_drop_or">Or</span>
                                    </div>
                                    <div className="file_upload_btndiv">
                                        <button className="file_upload_btn">Browse File</button>
                                    </div>
                                    <input type="file" className="file_upload_input" onChange={(e) => FileUpladChange(e)} />
                                </div>
                            }
                        </div>
                    </div>
                    {/* <div className="react_pagination">
            <Pagination
            activePage={activepage}
            itemsCountPerPage={5}
            totalItemsCount={450}
            pageRangeDisplayed={5}
            onChange={()=> pageChange()}
        />
            </div>
            <div className="table-responsive">
                <div className="fileupload_table">
                    <div className="fileupload_headdiv">
                        <div className="fileupload_head">Dealer Name</div>
                        <div className="fileupload_head fileupload_bodytext_uname">User Name</div>
                        <div className="fileupload_head">State</div>
                        <div className="fileupload_head">City</div>
                        <div className="fileupload_head">Location</div>
                        <div className="fileupload_head fileupload_delteImgdiv"></div>
                    </div>
                    <div className="fileupload_bodydiv">
                        <div className="fileupload_body">
                        <span className="fileupload_body_head_span">Dealer Name :</span>
                            Axis Bank Branch, Ahmedabad Main Branch
                        </div>
                        <div className="fileupload_body fileupload_bodytext_uname">
                        <span className="fileupload_body_head_span">User Name :</span>
                            47563
                        </div>
                        <div className="fileupload_body">
                        <span className="fileupload_body_head_span">State :</span>
                            Gujarat
                        </div>
                        <div className="fileupload_body">
                        <span className="fileupload_body_head_span">City :</span>
                            Ahmedabad
                        </div>
                        <div className="fileupload_body">
                        <span className="fileupload_body_head_span">Location :</span>
                            Trishul
                        </div>
                        <div className="fileupload_body fileupload_delteImgdiv">
                            <img src={Delete} className="file_upload_imgtag" alt="delete"/>
                        </div>
                    </div>
                    <div className="fileupload_bodydiv">
                        <div className="fileupload_body">Axis Bank Branch, Ahmedabad Main Branch</div>
                        <div className="fileupload_body fileupload_bodytext_uname">47563</div>
                        <div className="fileupload_body">Gujarat</div>
                        <div className="fileupload_body">Ahmedabad</div>
                        <div className="fileupload_body">Trishul</div>
                        <div className="fileupload_body fileupload_delteImgdiv">
                            <img src={Delete} className="file_upload_imgtag" alt="delete"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="react_pagination">
            <Pagination
            activePage={activepage}
            itemsCountPerPage={5}
            totalItemsCount={450}
            pageRangeDisplayed={5}
            onChange={()=> pageChange()}
        />
            </div> */}
                </div>
            </div>
        </div>
    );
}

export default FileUpload;
