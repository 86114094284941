import React, { useState, useEffect } from "react";
import "../css/slick.css"
import "../css/slick-theme.css"
import Slider from "react-slick";
import ProdDef from '../images/slider-white.jpg'
import Add from '../images/add.png'
import Edit from '../images/edit.png'
import Delete from '../images/delete.png'
import ImageLoader from "react-load-image";
import $ from 'jquery'
import { apiCallingPost} from "../service";
import AWS from "aws-sdk";
var s3;
//var bannerList = [];
//var oldvalue;

function LocalPageBanner(props) {
  const [bannerArray, setBannerArray] = useState(props.bannerArray ? JSON.parse(props.bannerArray) : [])
  const [imageFileName, setImageFileName] = useState("")
  const [imageFullFilePath, setImageFullFilePath] = useState("")
  const [file, setFile] = useState("")
  const [imageNamePreview, setImageNamePreview] = useState("")
  const [imageList, setImageList] = useState([
    {
      imageFileName: null,
      altText: "",
      sortOrder: "",
      bannerText: "",
    },
  ])
  const [editImage, setEditImage] = useState("")
  const [passingType, setPassingType] = useState(false)
  const [s3BucketMainFolder, setS3BucketMainFolder] = useState("")
  const [accessKey, setAccessKey] = useState("")
  const [secretKey, setSecretKey] = useState("")
  const [s3bucket, setS3bucket] = useState("")
  const [s3Details, setS3Details] = useState("")

  useEffect(() => {
    getAwsDetails();
  }, [props]);

  const getAwsDetails = () => {
    let postData = {
      functionName: "awsCredentials"
    };
    apiCallingPost(postData).then((data) => {
      // console.log("decoded", data)
      if (data.data.success === "1") {
        var decoded = data.data.result.s3Details;
        // console.log("decoded111", decoded)

        AWS.config.update({
          accessKeyId: decoded.accessKey,
          secretAccessKey: decoded.secretKey,
          region: "ap-south-1"
        });
        var bucketParams = {
          Bucket: decoded.s3bucket
        };
        s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
        setS3Details(s3)
        setS3BucketMainFolder(decoded.mainFolder)
        setAccessKey(decoded.accessKey)
        setSecretKey(decoded.secretKey)
        setS3bucket(decoded.s3bucket)
        // resolve(true);
      }
      else {
        // console.log('error:home:getAwsDetails:', data.errorMessage)
      }
    })
    // })
}
  const bannerImageLoad = (e) => {
    if (e.target.files[0].size > 2097152) {
      // 2 MiB for bytes.
      alert("File size must be below 2MB!");
      return;
    }
    var fileObj = e.target.files[0];
    let str = fileObj.name;
    var last = str.substring(str.lastIndexOf("/") + 1, str.length);
    let S3BucketMainFolder = window.sessionStorage.getItem('S3BucketMainFolder');
    let fileName = 'himalaya_' + new Date().getTime() + last;
    //console.log("fileName....", fileName);
    let reader = new File([fileObj], fileName, {
      type: "image/jpeg",
      lastModified: Date.now()
    });
    let fullFilePath = S3BucketMainFolder + "/" + fileName;
    // console.log('fullFilePath...............', fullFilePath)
    // console.log("reader....", reader);
    $("#hidBannerImageName").val(str)
    document.getElementById("bannerPreview").style.display = 'block'
    $("#bannerPreview").attr("src", str)

    setImageFullFilePath(fullFilePath)
    setFile(reader)
    setImageFileName(fileName)
    setImageNamePreview(window.URL.createObjectURL(e.target.files[0]))
  }

  const saveBanner = async (mode, type, imageListArray, index) => {
    //debugger
    let altText, sortBanner
    var flag = true
    if (flag === true) {
      let imageName = imageFileName ? imageFileName : imageListArray.imageFileName
      if (type === "Add" && passingType === false) {
        // console.log("1")
        altText = $("#bannerAltText").val()
        sortBanner = $("#sortBanner").val()
        imageListArray = {
          imageFileName: imageName,
          altText: altText,
          sortOrder: sortBanner,
          bannerText: "",
        };
        bannerArray.push(imageListArray)
        setBannerArray(bannerArray)
        // console.log("bannerArray---", bannerArray);
      }
      else if (type === "Edit" && passingType === true) {
        // console.log("2222***22222222")
        altText = document.getElementById("bannerAltText" + index).value
        // console.log("altText....***....", altText)
        sortBanner = $(".sortBanner" + index).val()
        bannerArray[index] = {
          imageFileName: imageName,
          altText: altText,
          sortOrder: sortBanner,
          bannerText: "",
        };
        let copyAppendValues = [...bannerArray];
        copyAppendValues.splice(index, 1);
        setBannerArray(bannerArray)
      }
      else {
      }
      const postData = {
        functionName: "saveBanner",
        banner: JSON.stringify(bannerArray),
        config_key: "Banner1"
      }
      // console.log("postData---", postData);
      if ($("#hidBannerImageName").val() !== "") {
        await saveImageUpload()
      }
      apiCallingPost(postData).then((data) => {
        cancelAboutBanner()
        //console.log("saveCourse data ", data)
        if (data.data.success == 1) {
          setTimeout(() => {
            props.getData()
          }, 2000);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
          $("#hidBannerImageName").val('')
        }
      })
    }
  }

  const saveImageUpload = () => {
    s3Details.upload(
      {
        Key: imageFullFilePath,
        Body: file,
        ACL: "public-read"
      },
      (err, data) => {
        // console.log("err", err);
        if (err) {
          alert(
            "There was an error uploading your photo: ",
            err.message
          );
          return null
        }
        else {
          alert("image upload successfully")
          setTimeout(() => {
              window.location.reload();
            }, 500);
        }

      }
    )
  }
  const AddBanner = (mode) => {
    if(mode === "Add") {
      $(".main_banner").addClass("editActive");
      $(".main_banner_list").hide();
      setImageNamePreview("")
    }
  }
  const setData = (e, index) => {
    let val = e.target.value;
    var bannerListData = [...bannerArray]
    bannerListData[index].altText = val
    setBannerArray(bannerListData)
  }
  const setDataSortOrder = (e, index) => {
    let val = e.target.value;
    var bannerListData = [...bannerArray]
    bannerListData[index].sortOrder = val
    setBannerArray(bannerListData)
  }

  const editBanner = (e, imageList, index) => {
    //console.log("imageList = ", imageList);
    $(".main_banner").css("z-index","999");
    $(".main_banner_list" + index).addClass("editActive");
    $(".bannerAltText" + index).val(imageList.altText)
    $(".sortBanner" + index).val(imageList.sortOrder)
    setEditImage(e);
    setPassingType(true)
    setImageNamePreview(props.bannerPath+imageList.imageFileName)
  }
  const deleteBanner = (imageList, index) => {
    if (window.confirm("Do you want to delete?")) {
      let bannerArrayDelete = bannerArray;
      bannerArrayDelete.splice(index, 1);
      setBannerArray(bannerArrayDelete);
      // console.log("bannerArray =", bannerArray); mode, type, imageList, index
      saveBanner("", "", bannerArray, "");
    } else {
      return false;
    }

  }
  const cancelAboutBanner = () => {
    $(".main_banner, .main_banner_list").removeClass("editActive");
    $(".main_banner_list").show();
    $("#bannerAltText").val("");
    $("#sortBanner").val("");
    setPassingType(false)
    $(".main_banner").css("z-index","0");
  }
  const Preloader = (props) => {
    return <img src={ProdDef} className="bannerItemImg"></img>
  }
  var settingsBanner = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipe: false,
    draggable: false,
    lazyLoad: true,
  };
  return (
    <>
     {window.sessionStorage.getItem("isLogedIn") === "1" ?
        (window.sessionStorage.getItem("roleName") === "SuperAdmin") && (
          <>
           {JSON.parse(props.bannerArray).length < 3 ?
            <div className="adminIconMain">
              <span
                className="editIcon addButton"
                onClick={(e) => {
                  AddBanner("Add");
                }}
              >
                <img src={Add} alt="Add" />
              </span>
            </div>
            :""}

            <div className="edit-banner-section">
              <div className="imageEditListBanner">
                <span className="fileUpload">
                  <input type="file" id="fileUploadBannerAdd" accept="image/png,image/jpeg" onChange={(e) => bannerImageLoad(e)} />
                  <label htmlFor="fileUploadBannerAdd">Upload media</label>
                  <input type="hidden" id="hidBannerImageName" />
                  <span className="bannerImageAlert">
                    <span className="bannerSizeText"><strong>Format:</strong> jpeg or png</span>
                    <span className="bannerSizeText"><strong>Size:</strong> less than 1Mb</span>
                    <span className="bannerSizeText"><strong>Dimension:</strong> 703*704 px</span>
                    <span className="bannerTextAlert">Please make sure your uploaded media is in jpeg or png format and less than 1 MB and 703*704 px photo size</span>
                  </span>
                </span>
                <img className={(imageNamePreview !== "" && imageNamePreview !== null) ? "imgPreview" : "imgPreview d-none"} id="bannerPreview" src={imageNamePreview} alt="sliderpreview" />
              </div>
              <div className="col-md-5 mx-auto">
                <input type="text" className="editInput" id="bannerAltText" placeholder="Alt Text" />
                <input type="number" className="editInput" id={"sortBanner"} placeholder="Sort Order" />
              </div>
              <div className="editPanelButton">
                {/* <span className="saveButton saveDraft" onClick={(e) => { saveBanner("DRAFT", "Add") }}>Save Draft</span> */}
                <span className="saveButton" id={"submitBanner"} onClick={(e) => { saveBanner("Save", "Add", "") }}>Submit</span>
                <span className="cancelButton" onClick={(e) => { cancelAboutBanner() }}>Cancel</span>
              </div>
            </div>
          </>
        )
        : ""}
      <div className="m-0 gapY_10">
        
      <Slider {...settingsBanner}>
        {bannerArray.length > 0 ?
          bannerArray.map((imageList, index) => (
            <div id={"main_banner_list" + index} key={index} className={"main_banner_list main_banner_list" + index} >
              {imageList !== null &&
                <img src={props.bannerPath
                  + imageList.imageFileName} alt="slider" className="img-fluid main_banner_list_img" width="1200" height="600"/>
              }
              {window.sessionStorage.getItem("isLogedIn") === "1" ?
                (window.sessionStorage.getItem("roleName") === "SuperAdmin") && (
                  <>
                    <div className="adminIconMain adminIconMainTop">
                      <span
                        className="editIcon editButton"
                        onClick={(e) => {
                          editBanner("Edit", imageList, index);
                        }}
                      >
                        <img src={Edit} alt="Edit" />
                      </span>
                      <span className="editIcon DeleteIcon" onClick={() => deleteBanner(imageList, index)}>
                        <img src={Delete} alt="Delete" />
                      </span>
                    </div>

                    <div className="edit-banner-section">
                      <div className="imageEditListBanner">
                        <span className="fileUpload">
                          <input type="file" id={"fileUploadBanner" + index} accept="image/png,image/jpeg" onChange={(e) => bannerImageLoad(e)} />
                          <label htmlFor={"fileUploadBanner" + index}>Upload media</label>
                          <input type="hidden" id="hidBannerImageName" />
                          <span className="bannerImageAlert">
                            <span className="bannerSizeText"><strong>Format:</strong> jpeg or png</span>
                            <span className="bannerSizeText"><strong>Size:</strong> less than 1Mb</span>
                            <span className="bannerSizeText"><strong>Dimension:</strong> 703*704 px</span>
                            <span className="bannerTextAlert">Please make sure your uploaded media is in jpeg or png format and less than 1 MB and 703*704 px photo size</span>
                          </span>
                        </span>
                        <img className="imgPreview" id={"bannerPreview" + index} src={imageNamePreview} alt="sliderpreview" />
                      </div>
                      <div className="col-md-5 mx-auto">
                        <input type="text" className={"editInput bannerAltText" + index}
                          id={"bannerAltText" + index}
                          value={imageList.altText}
                          onChange={(e) => setData(e, index)}
                          placeholder="Alt Text" />

                        <input type="number"
                          className={"editInput sortBanner" + index}
                          id={"sortBanner" + index}
                          value={imageList.sortOrder}
                          onChange={(e) => setDataSortOrder(e, index)}
                          placeholder="Sort Order" />
                      </div>
                      <div className="editPanelButton">
                        {/* <span className="saveButton saveDraft" onClick={(e) => { saveBanner("DRAFT", "Edit", imageList, index) }}>Save Draft</span> */}
                        <span className="saveButton" id={"submitBanner"} onClick={(e) => { saveBanner("Save", "Edit", imageList, index) }}>Submit</span>
                        <span className="cancelButton" onClick={(e) => { cancelAboutBanner() }}>Cancel</span>
                      </div>
                    </div>
                  </>
                )
                : ""}
            </div>
          ))
          :
          <div className="main_banner_list">
            <ImageLoader src={ProdDef} >
              <img alt="Error"/>
              <div>Error!</div>
              <Preloader />
            </ImageLoader>
          </div>
        }
      </Slider>
      </div>
    </>
  );
}

export default LocalPageBanner;
