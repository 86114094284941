import React from "react";
import AdminHeader from "./admin_header";
import DashboardList from "./dashboard_list";
function Dashboard() {
  return (
    <div className="dashboard_wrapper">
       <AdminHeader/>
       <div className="container">
          <h1 className="main_heading_dashboard_wrapper">Dashboard</h1>
      </div>
       <DashboardList/>
    </div>
  );
}

export default Dashboard;
