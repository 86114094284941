import React from "react";
import '../css/bootstrap.css'
 import '../css/edit_style.css'
import '../css/edit_responsive.css'
// import '../css/edit_style.min.css'
// import '../css/edit_responsive.min.css'
import Logo from '../images/logo.png'
import {FaSignInAlt } from "react-icons/fa";
let currentLocationFlag = false
function AdminHeader() {
    const logOut = () => {
        sessionStorage.clear();
        window.location.href = "/login"
    }
  return (
    <div className="main_header">
    <div className="container">
        <div className="dashboard_header">
        <div className="dashboard_logo">
            <a href="/"><img src={Logo} className="dashbaord_logoimg" alt="logo"/></a>
        </div>
        <div className="dashboard_headerlist">
            <ul className="nav">
                <li className="dashboard_list">
                <span className="dashboard_admintext">Admin</span>
                <FaSignInAlt className="dashboard_listsvg"/>
                <ul className="nav dashboard_subul">
                    <li className="w-100" onClick={logOut}><span  className="submenu_litag">LogOut</span></li>
                </ul>
                </li>
            </ul>
        </div>
        </div>
    </div>
    </div>
  );
}

export default AdminHeader;
