import React from "react";
import uniImg01 from '../images/img01.png'
// import uniImg02 from '../images/uni02.png'
// import uniImg03 from '../images/uni03.png'
import { FiChevronRight } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ImageLoader from 'react-load-image';

function LandingPageLocationList(props) {

  const viewPage = (siteId) => {
    window.sessionStorage.setItem("siteId", siteId)
  }
  function Preloader() {
    return <img src={uniImg01} alt="location image" className="view_locationimg"></img>
  }
  return (
    props.siteList.length > 0 && 
    <div className="row view_location" id="store_location_list" ref={props.ViewLocationRef} tabIndex={0}>
      <div className="col-md-12">
        <h2 className="view_locatior_heading">Stores in your city :</h2>
      </div>
      {/* {console.log("props.siteList---", props.siteList)} */}
      {props.siteList.map((data, index) => (
        <div className="col-lg-4 col-md-6 viewlocation_gap" key={index}>
          <div className='view_locationimgdiv'>
            <ImageLoader src={data.image} >
              <img className="view_locationimg" alt={"location image"} />
              <Preloader />
            </ImageLoader>
            <h3 className='view_location_head'>{(data.centerName.replaceAll('_', ', '))}</h3>
            <div className='view_location_para'>
              <p className="view_locationpara">{data.centreAddress + ", " + data.city + ", " + data.pinCode}</p>
            </div>
            <Link to={"/"
              // +data.city+"/"
              + (data.displayName.replaceAll('_', '-')).replaceAll(',', '').replaceAll(/ /g, "-")}
              onClick={(e) => viewPage(data.siteId)}
              className='view_location_navg'>
              View Location
              <span className='view_location_navgspan'>
                <FiChevronRight />
              </span>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default LandingPageLocationList;
