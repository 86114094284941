import React, { useEffect } from 'react';
import { apiCallingPost } from '../service'
import question from "../images/question.png"
import $ from "jquery";
//import question from "../images/question.png"
function HeaderSocialLinks(props) {
  useEffect(() => {
    if (props.allowSocialMediaEdit === "1") {
      $("#chkAllowAccess").prop("checked", true);
    } else {
      $("#chkAllowAccess").prop("checked", false);
    }
    if (props.allowSocialBannerEdit === "1") {
      $("#chkAllowAccess1").prop("checked", true);
    } else {
      $("#chkAllowAccess1").prop("checked", false);
    }
  }, [props.allowSocialMediaEdit, props.allowSocialBannerEdit]);
  const handlContactClick = ()=> {
    $("#processingContact").show()
    $("#saveContact").hide()
    var socialLinks = $("#contactNo").val() + '~' + $("#fb").val() + '~' + $("#insta").val() + '~' + $("#twitter").val() + '~' + $("#youtube").val() + '~' + $("#gTagAdd").val() + '~' + $("#gaAdd").val() + '~' + $("#fbTrackingAdd").val() + '~' + $("#linkedin").val();
    if (window.sessionStorage.getItem('roleName') === "Admin" && props.allowSocialMediaEdit === "0") {
      socialLinks = $("#contactNo").val() + '~' + props.facebook + '~' + props.instagram + '~' + props.twitter + '~' + props.youtube + '~' + props.gTag + '~' + props.ga + '~' + props.fbTracking + '~' + props.linkedin
    }
    const postData = {
      contactNo: socialLinks,
      siteId: props.siteData[0].siteId,
      enableSocialMedia: ($("#chkAllowAccess").is(":checked") === true ? "1" : "0"),
      functionName: "saveContactNo"
    }
    apiCallingPost(postData).then((data) => {
      //  console.log("data.....", data)
       if (data.data.success == 1) {
         alert("Data successfully submitted")
         $("#processingContact").hide()
         $("#saveContact").show()
         props.getData();
         //window.location.reload();
       }
     })
  }
    return (
      <>
        {/* <label>Contact No:-</label>
        <input type="text" autoComplete="off" id="contactNo" className="socialLinksList" defaultValue={this.props.contactNo} style={{ width: "180px" }} maxLength={10} placeholder="Contact No "></input> */}
        {(window.sessionStorage.getItem('roleName') === "Admin" && props.allowSocialMediaEdit === "1") || window.sessionStorage.getItem('roleName') == "SuperAdmin" ?
        <>
          {/* <div className="socialLinksCommon"> */}
      <div className="socialLinksAdmin">
        <p className="socialLinksAdmin-heading">Social Handler</p>
          {/* <div className='col-lg-4 col-md-6 p-0 mb-2'>
            <span className="head-social-help">
              <label>FB:-</label>
              <input type="text" autoComplete="off" id="fb" className="socialLinksList"
                defaultValue={this.props.facebook}
                placeholder="Facebook "></input>
            </span>
            </div>
            <div className='col-lg-4 col-md-6 p-0 mb-2'>
            <span className="head-social-help">
              <label>Instagram:-</label>
              <input type="text" autoComplete="off" id="insta" className="socialLinksList"
                defaultValue={this.props.instagram}
                placeholder="Instagram "></input>
            </span>
            </div>
            <div className='col-lg-4 col-md-6 p-0 mb-2'>
            <span className="head-social-help">
              <label>Twitter:-</label>
              <input type="text" autoComplete="off" id="twitter" className="socialLinksList"
                defaultValue={this.props.twitter}
                placeholder="Twitter "></input>
            </span>
            </div> */}
            {/* <span className="head-social-help">
              <label>YouTube:-</label>
              <input type="text" autoComplete="off" id="youtube" className="socialLinksList"
                defaultValue={this.props.youtube} style={{ width: "180px" }} placeholder="YouTube "></input>
            </span>
            <span className="head-social-help">
              <label>LinkedIn:-</label>
              <input type="text" id="linkedin" autoComplete="off" className="socialLinksList"
                defaultValue={this.props.linkedin} style={{ width: "180px" }} placeholder="LinkedIn "></input>
            </span> */}
            <div className='col-lg-4 col-md-6 p-0 mb-2'>
            <span className={"head-social-help1" + (window.sessionStorage.getItem('roleName') === "Admin" ? " head-social-help-link" : "")}>
              <label>GTM:-</label>
              <input type="text" autoComplete="off" id="gTagAdd" className="socialLinksList"
                defaultValue={props.gTag}  placeholder="GTM"></input>
              <a href="https://support.google.com/tagmanager/answer/6102821?hl=en" target="_blank">
                <img src={question} alt="question" /></a>
            </span>
            </div>
            <div className='col-lg-4 col-md-6 p-0 mb-2'>
            <span className={"head-social-help1" + (window.sessionStorage.getItem('roleName') === "Admin" ? " head-social-help-link" : "")}>
              <label>Google Analytics:-</label>
              <input type="text" autoComplete="off" id="gaAdd" className="socialLinksList" defaultValue={props.ga}  placeholder="Google Analytics"></input>
              <a href="https://searchbusinessanalytics.techtarget.com/definition/Google-Analytics" target="_blank">
                <img src={question} alt="question" /></a></span>
                </div>
                {/* <div className='col-lg-4 col-md-6 p-0 mb-2'>
            <span className={"head-social-help1" + (window.sessionStorage.getItem('roleName') === "Admin" ? " head-social-help-link" : "")}>
              <label>FB Tracking:-</label>
              <input type="text" autoComplete="off" id="fbTrackingAdd" className="socialLinksList" defaultValue={this.props.fbTracking}  placeholder="FB Tracking"></input>
              <a href="https://blog.hootsuite.com/facebook-pixel/" target="_blank"><img src={question} alt="" /></a></span>
              </div> */}
              <div className='col-lg-4 col-md-6 p-0 mb-2'>
            <span className={"head-social-help1" + (window.sessionStorage.getItem('roleName') === "Admin" ? " head-social-help-link" : "")}>
              <label style={{ display: (window.sessionStorage.getItem('roleName') === "SuperAdmin" ? 'inline-block' : 'none') }}>Allow Access:-  <img src={question} alt="" /></label>
              <input type="checkbox" id="chkAllowAccess" className="socialLinksList" style={{ width: "36px", cursor: 'pointer', display: (window.sessionStorage.getItem('roleName') === "SuperAdmin" ? 'inline-block' : 'none') }} placeholder="Allow Access"></input>
              {/* <label style={{ display: (window.sessionStorage.getItem('roleName') === "SuperAdmin" ? 'inline-block' : 'none') }}>Allow Access For Form:- <img style={{ cursor: 'pointer' }} src={question} alt="" title="Enable checkbox to display the Form." /></label>
              <input type="checkbox" id="chkAllowAccess1" className="socialLinksList" style={{ width: "70px", cursor: 'pointer', display: (window.sessionStorage.getItem('roleName') === "SuperAdmin" ? 'inline-block' : 'none') }} placeholder="Allow Access"></input> */}
            </span>
            </div>
          {/* </div> */}
          
          <div className='col-lg-4 col-md-6 p-0 mb-2'>
          <span className="saveButton m-0 mr-2" id="saveContact" onClick={(e) => { handlContactClick() }}>Submit</span>
          <span className="saveButton" id="processingContact" style={{ display: 'none' }} >Processing...</span>
          </div>
          </div> 
          </>
          : ""}
        {/* <span className="saveButton" id="saveContact" onClick={(e) => { this.handlContactClick() }}>Submit</span>
        <span className="saveButton" id="processingContact" style={{ display: 'none' }} >Processing...</span> */}
      
      </>
    )
  }
export default HeaderSocialLinks;