import { useEffect, useState } from "react";
import "../css/bootstrap.css";
import Logo from '../images/logo.png'
import Globe from '../images/globe.webp'
import * as Icon from "react-feather";
import $ from 'jquery'
import "../css/style.css";
import "../css/responsive.css";
// import "../css/style.min.css";
// import "../css/responsive.min.css";
function Header() {
    const [showMenu, SetshowMenu] = useState(true)
    const breakpoint = 991;
    const toggleMenu = () => {
        SetshowMenu(!showMenu)
    }
    if (showMenu) {
        document.body.classList.add('body_wrapper');
      } else {
        document.body.classList.remove('body_wrapper');
      }
    const toggleMenuClose =() =>{
        SetshowMenu(false)
    }
    useEffect(() => {
        if (window.innerWidth > breakpoint) {
            SetshowMenu(false)
        }
        else {
            SetshowMenu(false)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > breakpoint) {
                SetshowMenu(false)
            }
            else {
                SetshowMenu(false)
            }
        });
        $('.menu_listing_arrow').click(function(){
            $(this).find('svg').toggleClass('menu_listing_arrow_down')
            $(this).parent('.menu_listing_div').siblings('.dropdown_megamenu,.dropdown_single').slideToggle()
        })
        $('.inner_menu_down_icon').click(function(){
            $(this).parent('.inner_menu_mobile').siblings('.inner_dropdown_links').slideToggle()
            $(this).find('svg').toggleClass('menu_listing_arrow_down')
        })
    }, []);
    return (
        <div className="main_header">
            <div className="top_header">
                <div className="top_announcement">
                <a className="announcement_link" href="https://bit.ly/HEMA-WA"><span className="announcement_text">Welcome to Himalaya Wellness India!</span><span className="announcement_link_text">Got Questions? WhatsApp Us: 8951891930</span></a>
                </div>  
                <div className="top_globel">
                   <div className="container-fluid">
                   <a className="globe_tag" href="https://himalayaglobalholdings.com/">
                        <img src={Globe} alt="Globe" width={20} height={20}/>&nbsp;  Himalaya Global Holdings Ltd. (Our Parent)
                        </a>
                   </div>
                </div>              
                <div className={`main_menu ${showMenu ? "show" : ""}`}>
                    <div className="container-fluid">
                        <div className="manin_menu_hemalaya">
                            <a href="https://himalayawellness.in/" className="manin_menu_logo">
                                <img src={Logo} className="manin_menu_logo_img" alt="Logo" width="100" height="100"/>
                            </a>
                            <div className="nav manin_menu_barList">
                                <div className="menu_bar_close mobile_animation appear_delay_1">
                                    <Icon.X  onClick={()=>toggleMenuClose()}/>
                                </div>
                                <ul className="nav overflow_div">
                                <li className="menu_list_tag  mobile_animation appear_delay_2">
                                    <div className="menu_listing_div"><a href='https://himalayawellness.in/pages/our-story' className="menu_nav_tag" >About Us</a><span className="menu_listing_arrow"><Icon.ChevronDown/></span></div>
                                    <div className="dropdown_megamenu">
                                        <div className="inner_menus appear_animation appear_delay_1">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/our-story" className="inner_menus_head">Corporate Profile</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/our-story" className="dropdown_link">Our Story </a>
                                        <a href="https://himalayawellness.in/pages/our-mission-and-vision" className="dropdown_link"> Our Mission and Vision  </a>
                                        <a href="https://himalayawellness.in/pages/milestones" className="dropdown_link"> Milestones </a>
                                        <a href="https://himalayawellness.in/pages/people-and-culture" className="dropdown_link"> People and Culture  </a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_delay_2">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/global-leadership-team" className="inner_menus_head">Leadership</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/chairmans-message" className="dropdown_link">Chairman's Message</a>
                                        <a href="https://himalayawellness.in/pages/global-ceos-message" className="dropdown_link">Global CEO's Message</a>
                                        <a href="https://himalayawellness.in/pages/management-structure-hgml" className="dropdown_link"> Management Structure-HGML </a>
                                        <a href="https://himalayawellness.in/pages/global-leadership-team" className="dropdown_link">Global Leadership Team</a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_delay_3">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/podcast" className="inner_menus_head">Media</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/podcast" className="dropdown_link">Podcast</a>
                                        <a href="https://himalayawellness.in/a/blog" className="dropdown_link">Blog</a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_delay_4">
                                        <a href="https://www.himalaya.eco/" className="inner_menus_head">CSR</a>
                                        <a href="https://storelocator.himalayawellness.in/" className="inner_menus_head">Store Locator</a>
                                        <a href="https://himalayawellness.in/pages/careers" className="inner_menus_head">Careers</a>
                                        </div>
                                    </div>
                                </li>
                                <li className="menu_list_tag menu_list_single  mobile_animation appear_delay_3">
                                <div className="menu_listing_div"><a href='https://himalayawellness.in/pages/ayurveda-the-science-of-life' className="menu_nav_tag" >Our Research</a><span className="menu_listing_arrow"><Icon.ChevronDown/></span></div>
                                    <div className="dropdown_single">
                                        <div className="inner_menus">
                                        <a href="https://himalayawellness.in/pages/ayurveda-the-science-of-life" className="dropdown_link">Ayurveda/The Science of Life</a>
                                        <a href="https://himalayawellness.in/pages/our-science" className="dropdown_link">Our Science</a>
                                        <a href="https://himalayawellness.in/pages/research-at-the-heart" className="dropdown_link">Research at the Heart</a>
                                        <a href="https://himalayawellness.in/pages/formulating-a-herbal-drug" className="dropdown_link">Formulating a Herbal Drug</a>
                                        <a href="https://himalayawellness.in/pages/new-research-areas" className="dropdown_link">New Research Areas</a>
                                        </div>
                                        </div>
                                </li>
                                <li className="menu_list_tag  mobile_animation appear_delay_4">
                                    <div className="menu_listing_div"><a href='https://himalayawellness.in/pages/search-results-page?collection=all' className="menu_nav_tag" >Products</a><span className="menu_listing_arrow"><Icon.ChevronDown/></span></div>
                                    <div className="dropdown_megamenu">
                                        <div className="inner_menus appear_animation appear_delay_1">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/search-results-page?collection=animal-health" className="inner_menus_head">Animal Health</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=aquaculture" className="dropdown_link">Aquaculture</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=companion-care" className="dropdown_link">Companion Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=livestock" className="dropdown_link"> Livestock </a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=poultry" className="dropdown_link">Poultry</a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_delay_2">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/search-results-page?collection=baby-care" className="inner_menus_head">Baby Care</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=baby-liquid-cleanser" className="dropdown_link">Baby Liquid Cleanser</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=baby-laundry-wash" className="dropdown_link">Baby Laundry Wash</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=bath" className="dropdown_link"> Bath </a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=diapers" className="dropdown_link">Diapers</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=gift-packs" className="dropdown_link">Gift Packs</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=post-bath" className="dropdown_link">Post-Bath</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=pre-bath" className="dropdown_link">Pre-Bath</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=kids-oral-care" className="dropdown_link">Kids Oral Care</a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_delay_3">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/search-results-page?collection=himalaya-for-moms" className="inner_menus_head">Himalaya FOR MOMS</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=body-care-moms" className="dropdown_link"> Body Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=gift-pack-moms" className="dropdown_link">Gift Packs</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=intimate-care" className="dropdown_link">Intimate Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=nursing-care" className="dropdown_link">Nursing Care</a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_delay_4">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/search-results-page?collection=home-care" className="inner_menus_head">Home Care</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=floor-cleaners" className="dropdown_link"> Floor cleaners</a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_delay_5">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/search-results-page?collection=personal-care" className="inner_menus_head">Personal Care</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=body-care" className="dropdown_link">Body Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=eye-care" className="dropdown_link">Eye Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=face-care" className="dropdown_link">Face Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=hair-care" className="dropdown_link">Hair Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=lip-care" className="dropdown_link"> Lip Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=mens-care" className="dropdown_link">Men's Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=oral-care" className="dropdown_link">Oral Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=kits" className="dropdown_link">Kits</a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_delay_6">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/search-results-page?collection=pharmaceuticals" className="inner_menus_head">Pharmaceuticals</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=childrens-health" className="dropdown_link">Children's Health</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=derma-care" className="dropdown_link">Derma Care</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=general-health" className="dropdown_link">General Health</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=oral-health" className="dropdown_link">Oral Health</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=womens-health" className="dropdown_link">Women's Health</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=mens-health" className="dropdown_link"> Men’s Health </a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_animation appear_delay_7">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/search-results-page?collection=nutrition" className="inner_menus_head">Nutrition</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=adult" className="dropdown_link">Adult</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=kids" className="dropdown_link">Kids</a>
                                        </div>
                                        </div>
                                        <div className="inner_menus appear_animation appear_delay_8">
                                        <div className="inner_menu_mobile"><a href="https://himalayawellness.in/pages/search-results-page?collection=wellness" className="inner_menus_head">Wellness</a><span className="inner_menu_down_icon"><Icon.ChevronDown/></span></div>
                                        <div className="inner_dropdown_links">
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=general" className="dropdown_link">General</a>
                                        <a href="https://himalayawellness.in/pages/search-results-page?collection=pure-herbs" className="dropdown_link">Pure Herbs</a>
                                        <a href="https://himalayawellness.in/products/himalaya-adult-diapers" className="dropdown_link">Adult Diapers </a>
                                        </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="himalaya_globel  mobile_animation appear_delay_5">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-6">
                                            <a className="globe_tag globe_tag_mobile" href="https://himalayaglobalholdings.com/">
                                                <img src={Globe} alt="Globe"  width={20} height={20}/>&nbsp;  Himalaya Global Holdings Ltd. (Our Parent)
                                                </a>
                                            </div>
                                            <div className="col-6">
                                            <a href="https://himalayawellness.in/account/login?return_url=%2Faccount"  className="globe_tag globe_tag_left">Log in
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="header_social_div  mobile_animation appear_delay_6">
                                <a className="nav_link_tag" target="_blank" rel="noopener" href="https://www.instagram.com/himalayapersonalcare" title="Himalaya Wellness (India) on Instagram">
                                    <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-instagram" viewBox="0 0 32 32">
                                        <path fill="#444" d="M16 3.094c4.206 0 4.7.019 6.363.094 1.538.069 2.369.325 2.925.544.738.287 1.262.625 1.813 1.175s.894 1.075 1.175 1.813c.212.556.475 1.387.544 2.925.075 1.662.094 2.156.094 6.363s-.019 4.7-.094 6.363c-.069 1.538-.325 2.369-.544 2.925-.288.738-.625 1.262-1.175 1.813s-1.075.894-1.813 1.175c-.556.212-1.387.475-2.925.544-1.663.075-2.156.094-6.363.094s-4.7-.019-6.363-.094c-1.537-.069-2.369-.325-2.925-.544-.737-.288-1.263-.625-1.813-1.175s-.894-1.075-1.175-1.813c-.212-.556-.475-1.387-.544-2.925-.075-1.663-.094-2.156-.094-6.363s.019-4.7.094-6.363c.069-1.537.325-2.369.544-2.925.287-.737.625-1.263 1.175-1.813s1.075-.894 1.813-1.175c.556-.212 1.388-.475 2.925-.544 1.662-.081 2.156-.094 6.363-.094zm0-2.838c-4.275 0-4.813.019-6.494.094-1.675.075-2.819.344-3.819.731-1.037.4-1.913.944-2.788 1.819S1.486 4.656 1.08 5.688c-.387 1-.656 2.144-.731 3.825-.075 1.675-.094 2.213-.094 6.488s.019 4.813.094 6.494c.075 1.675.344 2.819.731 3.825.4 1.038.944 1.913 1.819 2.788s1.756 1.413 2.788 1.819c1 .387 2.144.656 3.825.731s2.213.094 6.494.094 4.813-.019 6.494-.094c1.675-.075 2.819-.344 3.825-.731 1.038-.4 1.913-.944 2.788-1.819s1.413-1.756 1.819-2.788c.387-1 .656-2.144.731-3.825s.094-2.212.094-6.494-.019-4.813-.094-6.494c-.075-1.675-.344-2.819-.731-3.825-.4-1.038-.944-1.913-1.819-2.788s-1.756-1.413-2.788-1.819c-1-.387-2.144-.656-3.825-.731C20.812.275 20.275.256 16 .256z"></path>
                                        <path fill="#444" d="M16 7.912a8.088 8.088 0 0 0 0 16.175c4.463 0 8.087-3.625 8.087-8.088s-3.625-8.088-8.088-8.088zm0 13.338a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 1 1 0 10.5zM26.294 7.594a1.887 1.887 0 1 1-3.774.002 1.887 1.887 0 0 1 3.774-.003z"></path>
                                    </svg>
                                </a>
                                    <a className="nav_link_tag" target="_blank" rel="noopener" href="https://www.facebook.com/HimalayaPersonalCare" title="Himalaya Wellness (India) on Facebook">
                                    <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-facebook" viewBox="0 0 14222 14222">
                                        <path d="M14222 7112c0 3549.352-2600.418 6491.344-6000 7024.72V9168h1657l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4968.72C2600.418 13603.344 0 10661.352 0 7112 0 3184.703 3183.703 1 7111 1s7111 3183.703 7111 7111zm-8222 7025c362 57 733 86 1111 86-377.945 0-749.003-29.485-1111-86.28zm2222 0v-.28a7107.458 7107.458 0 0 1-167.717 24.267A7407.158 7407.158 0 0 0 8222 14137zm-167.717 23.987C7745.664 14201.89 7430.797 14223 7111 14223c319.843 0 634.675-21.479 943.283-62.013z"></path>
                                    </svg>
                                    </a>
                                    <a className="nav_link_tag" target="_blank" rel="noopener" href="https://www.youtube.com/user/HimalayaHerbalsIndia" title="Himalaya Wellness (India) on YouTube">
                      <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-youtube" viewBox="0 0 21 20">
                        <path fill="#444" d="M-.196 15.803q0 1.23.812 2.092t1.977.861h14.946q1.165 0 1.977-.861t.812-2.092V3.909q0-1.23-.82-2.116T17.539.907H2.593q-1.148 0-1.969.886t-.82 2.116v11.894zm7.465-2.149V6.058q0-.115.066-.18.049-.016.082-.016l.082.016 7.153 3.806q.066.066.066.164 0 .066-.066.131l-7.153 3.806q-.033.033-.066.033-.066 0-.098-.033-.066-.066-.066-.131z"></path>
                      </svg>
                    </a>
                    <a className="nav_link_tag" target="_blank" rel="noopener" href="https://twitter.com/HimalayaIndia" title="Himalaya Wellness (India) on Twitter">
                      <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-twitter" viewBox="0 0 32 32">
                        <path fill="#444" d="M31.281 6.733q-1.304 1.924-3.13 3.26 0 .13.033.408t.033.408q0 2.543-.75 5.086t-2.282 4.858-3.635 4.108-5.053 2.869-6.341 1.076q-5.282 0-9.65-2.836.913.065 1.5.065 4.401 0 7.857-2.673-2.054-.033-3.668-1.255t-2.266-3.146q.554.13 1.206.13.88 0 1.663-.261-2.184-.456-3.619-2.184t-1.435-3.977v-.065q1.239.652 2.836.717-1.271-.848-2.021-2.233t-.75-2.983q0-1.63.815-3.195 2.38 2.967 5.754 4.678t7.319 1.907q-.228-.815-.228-1.434 0-2.608 1.858-4.45t4.532-1.842q1.304 0 2.51.522t2.054 1.467q2.152-.424 4.01-1.532-.685 2.217-2.771 3.488 1.989-.261 3.619-.978z"></path>
                      </svg>
                    </a>
                                </li>
                                </ul>
                            </div>
 
                            <ul className="nav ml-auto align-items-center">
                                <li>
                                    <a href="https://himalayawellness.in/account/login?return_url=%2Faccount"  aria-label="account">
                                <svg aria-hidden="true" focusable="false" role="presentation" className="icon_icon_user" viewBox="0 0 64 64">
                                    <path d="M35 39.84v-2.53c3.3-1.91 6-6.66 6-11.41 0-7.63 0-13.82-9-13.82s-9 6.19-9 13.82c0 4.75 2.7 9.51 6 11.41v2.53c-10.18.85-18 6-18 12.16h42c0-6.19-7.82-11.31-18-12.16z"></path>
                                </svg>
                                </a>
                                </li>
                                <li className="nav-item menu_show_hide">
                                   <div className="menu_icon" onClick={()=>toggleMenu()}>
                                    <div className="menu_bar menu_bar1"></div>
                                    <div className="menu_bar menu_bar2"></div>
                                    <div className="menu_bar menu_bar3"></div>
                                   </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
