import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./himalaya/landing_page";
import LocalPage from "./himalaya/local_page";
import Login from "./cms/login";
import Dashboard from "./cms/dashboard";
import Reports from "./cms/enquire-reports";
import Listing from "./cms/listing";
import FileUpload from "./cms/file_upload";
import Addlocation from "./cms/add-edit-location";
import BannerImageUpload from "./cms/banner-image-upload";
import PageNotFound from "./himalaya/page-not-found";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
    <Route path="/" element={<LandingPage/>}/>
    {/* <Route path="/locator" element={<LocatorList/>}/> */}
    <Route path="/:url" element={<LocalPage/>}/>
    <Route path="/login" element={<Login/>}/>
    <Route path="/dashboard" element={<Dashboard/>}/>
    <Route path='/enquire-details' element={<Reports/>}/>
    <Route path='/store-details' element={<Listing/>}/>
    <Route path='/file-upload' element={<FileUpload/>}/>
    <Route path='/add-store' element={<Addlocation/>}/>
    <Route path='/banner-image-upload' element={<BannerImageUpload/>}/>
    <Route path="/index.htm" element={<LandingPage/>}/>
    <Route path="/index.html" element={<LandingPage/>}/>
    <Route path="*" element={<PageNotFound/>} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
