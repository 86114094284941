import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/slick.css';
import '../css/slick-theme.css';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';

const LocalPageYoutube = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    getYoutubeDataFn();
  }, []);

  const getYoutubeDataFn = () => {
    axios
      .get(
        `https://www.googleapis.com/youtube/v3/search?part=id&channelId=${process.env.REACT_APP_ChannelId}&key=${process.env.REACT_APP_Youtube_API_KEY}&maxResults=6&order=date&publishedAfter=2010-01-01T00:00:00Z`
      )
      .then((response) => {
        const videoIds = response.data.items.map((item) => item.id.videoId);
        setVideos(videoIds);
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  var settingsYoutube = {
    infinite: false,
    speed: 500,
    dots: false,
    variableWidth: true,
    swipeToSlide: true,
  };

  return (
    videos.length > 0 && (
      <div className="col-md-12 youtube-wrapper">
        <h2 className="main-head">Youtube </h2>
        <div className="youtube-section">
          <Slider {...settingsYoutube}>
            {videos.map((videoId) => (
              <div className="youtube-subList" key={videoId}>
                <ReactPlayer url={'https://www.youtube.com/embed/' + videoId} controls={true} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
  );
};

export default LocalPageYoutube;
