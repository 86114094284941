
import axios from "axios";

// const getDetailsPageWiseURL = 'https://vxrf65nsk3.execute-api.ap-south-1.amazonaws.com/himalaya_test/pagewisecms'
const getDetailsPageWiseURL = 'https://vxrf65nsk3.execute-api.ap-south-1.amazonaws.com/himalaya-prod/pagewisecms'
const getGoogleReviews = 'https://jkzdxlws1i.execute-api.ap-south-1.amazonaws.com/prod'

export function apiCallingPost(postData) {
  return axios.post(getDetailsPageWiseURL, postData)
    .then((data) => data)
    .then((res) => res)
}

export function getGoogleReviewDetails(postData) {
  return axios.post(getGoogleReviews, postData)
    .then((data) => data)
    .then((res) => res)
}

export function getCurrentState(latitude, longitude) {
  return fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng='+latitude+','+longitude+'&key='+process.env.REACT_APP_GOOGLE_MAP_API_KEY)
  
    .then(data => data.json())
    .then(res => res);
}
