import React, { useState, useEffect } from "react";
import "../css/slick.css"
import "../css/slick-theme.css"
import Slider from "react-slick";
import BannerDefaultImg from '../images/banner-deafult.jpg'
import ImageLoader from 'react-load-image';

function HomeBanner(props) {
  // const [banner, setbanner] = useState([])
  const [bannerData, setBannerData] = useState({});
  const [bannerPath, setBannerPath] = useState("");
  useEffect(() => {
    //const parsedData = JSON.parse(props.bannerData[0].banner);
    setBannerData(JSON.parse(props.bannerData[0].banner))
    setBannerPath(props.bannerData[0].bannerPath)
    // let data = bannerDefaultdata.filter((obj) => (obj.stateID === props.stateId))
    // setbanner(data)
  },[props])

  // const bannerDefaultdata = [
  //   {
  //     "imageFileName": "banner1.jpg",
  //     "altText": "banner",
  //     "stateID": "26"
  //   },
  //   {
  //     "imageFileName": "banner2.jpg",
  //     "altText": "banner",
  //     "stateID": "8"
  //   }
  // ]
  function Preloader() {
    return <img src={BannerDefaultImg} alt="Banner image" className="img-fluid main_banner_list_img"  width="1200" height="600"></img>
  }

  var settingsBanner = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipe: false,
    arrows: false,
    draggable: false,
    lazyLoad: true,
  };
  return (
        <Slider {...settingsBanner}>
          {
          // banner.length > 0 ?
          //   <div className="main_banner_list">              
          //     {banner.map((data, index) => (
          //       <img src={`${bannerPath + data.imageFileName}`} key={index} alt="Banner" className="img-fluid main_banner_list_img" />
          //     ))}
          //   </div>
          //   :
          
          JSON.parse(props.bannerData[0].banner) != {} &&
            JSON.parse(props.bannerData[0].banner).map((data, index) => (
              <div className="main_banner_list" key={index}>
              {/* {console.log("bannerData---111--", data)} */}
                <ImageLoader src={`${props.bannerData[0].bannerPath + data.imageFileName}`} >
                  <img alt={data.altText} className="img-fluid main_banner_list_img"  width="1200" height="600"/>
                  <Preloader />
                </ImageLoader>
              </div>
            ))
          }
        </Slider>
  );
}

export default HomeBanner;
