import { useState, useEffect } from "react";
import $ from "jquery"
import { apiCallingPost } from "../service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LandingPageFranchise({ franchiseClick,popupShow}) {
  const [credentials, setCredentials] = useState({ fullName: '', Email: '', Phone: '', City: '', PostalCode: '' });

  useEffect(() => {
    document.getElementById("fullName").focus()
  }, [])

//   useEffect(() => {
// alert(popupShow)
//   }, [popupShow])

  const handleInputChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const sendEnquiry = () => {
    let flag = true;
    const { fullName, Email, Phone, City, PostalCode } = credentials;
    const isValidEmail = (email) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
    if (fullName === "") {
      alert("Please enter full name.")
      document.getElementById("fullName").focus()
      flag = false
    }
    else if (Email === "") {
      alert("Please enter email id.")
      document.getElementById("Email").focus()
      flag = false
    }
    else if (!isValidEmail(Email)) {
      alert("Please enter a valid email address.");
      document.getElementById("Email").focus()
      flag = false
    }
    else if (!/^\d{10}$/.test(Phone)) {
      alert("Please enter a valid 10-digit phone number.");
      document.getElementById("Phone").focus()
      flag = false
    }
    else if (City === "") {
      alert("Please enter city.")
      document.getElementById("City").focus()
      flag = false
    }
    else if (PostalCode === "") {
      alert("Please enter postal code.")
      document.getElementById("PostalCode").focus()
      flag = false
    }
    else if( flag === true) {
      $("#submit-btn").prop('disabled', true);
      let postData = {
          functionName: "franchiseEnquirySave",
          customer_name: credentials.fullName,
          phone: credentials.Phone,
          email_id: credentials.Email,
          city: credentials.City,
          pincode: credentials.PostalCode
      }
      // console.log("postData---, ", postData);
      apiCallingPost(postData).then((data) => {
        // console.log(data);
        if (data.data.success === "1") {
          $("#submit-btn").removeAttr("disabled");
          toast.success("Thank You for your enquiry!!!");
          setTimeout(() => {
            handleClick()
          }, 1500);
        }
        else {
          $("#submit-btn").removeAttr("disabled");
          handleClick()
        }
      })

      setCredentials({
        fullName: '',
        Email: '',
        Phone: '',
        City: '',
        PostalCode: ''
      });
    }
  }
  const handleClick = () => {
    // Call the function passed from the parent component
    franchiseClick(false);
  };
  return (
    <>
      <ToastContainer />
      <div className={`enquiry-outer-popup ${popupShow ? 'show' : 'hide'}`}>
        <div className="enquiry-inner-div">
          <input
            type="text"
            className="form_control"
            placeholder="Full Name"
            value={credentials.fullName}
            onChange={handleInputChange}
            name="fullName"
            id="fullName"
          />
          <input
            type="email"
            className="form_control"
            placeholder="Email Address"
            value={credentials.Email}
            onChange={handleInputChange}
            name="Email"
            id="Email"
          />
          <input type="text"
            className="form_control"
            placeholder="Phone No"
            value={credentials.Phone}
            onChange={handleInputChange}
            maxLength={10}
            name="Phone"
            id="Phone"
          />
          <input type="text"
            className="form_control"
            placeholder="City"
            value={credentials.City}
            onChange={handleInputChange}
            name="City"
            id="City"
          />
          <input type="text"
            className="form_control"
            placeholder="Postal Code"
            value={credentials.PostalCode}
            onChange={handleInputChange}
            name="PostalCode"
            id="PostalCode"
          />
          <button type="button" id="submit-btn" className="button-theme mt-2" onClick={sendEnquiry}> Send Enquiry</button>
        </div>
      </div>
    </>
  );
}

export default LandingPageFranchise;
