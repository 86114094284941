import React, { useState } from 'react';
import $ from "jquery";
import { apiCallingPost } from "../service";

const LocalPageSeo = ({ siteData }) => {
    const [seoTitle, setSeoTitle] = useState('');
    const [seoKeyword, setSeoKeyword] = useState('');
    const [seoSlug, setSeoSlug] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
  
    const seoViewClick = () => {
      const seoPostData = {
        siteId: siteData[0].siteId,
        editMode: 'edit',
        userRole: window.sessionStorage.getItem('roleName'),
        pageId: '1',
        url: window.sessionStorage.getItem('url'),
        functionName: 'contentData',
      };
        // console.log('seoPostData',seoPostData)
        apiCallingPost(seoPostData).then((data) => {
            setSeoTitle(data.data.result.defaultSeo[0].seoTitle);
            setSeoKeyword(data.data.result.defaultSeo[0].seoKeyword);
            setSeoSlug(data.data.result.defaultSeo[0].seoSlug);
            setSeoDescription(data.data.result.defaultSeo[0].seoDescription);
      
            if (data.data.result.seoDetails[0] != null) {
              $('#seoTitle').val(data.data.result.seoDetails[0].seoTitle);
              $('#seoKey').val(data.data.result.seoDetails[0].seoKeyword);
              $('#SeoSlug').val(data.data.result.seoDetails[0].seoSlug);
              $('#seoDecription').val(data.data.result.seoDetails[0].seoDescription);
            } else {
              $('#seoTitle').val(data.data.result.defaultSeo[0].seoTitle);
              $('#seoKey').val(data.data.result.defaultSeo[0].seoKeyword);
              $('#SeoSlug').val(data.data.result.defaultSeo[0].seoSlug);
              $('#seoDecription').val(data.data.result.defaultSeo[0].seoDescription);
            }
          });
      
          $('#seoSection').addClass('editActive');
          $('#seoSection').show();
        };
      
        const seoCancel = () => {
          $('#seoSection').removeClass('editActive');
        };
      
        const generateSeo = () => {
          $('#seoTitle').val(seoTitle);
          $('#seoKey').val(seoKeyword);
          $('#SeoSlug').val(seoSlug);
          $('#seoDecription').val(seoDescription);
        };
        const saveSeo = () => {
            const newSeoTitle = $('#seoTitle').val();
            const newSeoKey = $('#seoKey').val();
            const newSeoSlug = $('#SeoSlug').val();
            const newSeoDescription = $('#seoDecription').val();
        
            const seoPostData = {
              siteId: siteData[0].siteId,
              seoSlug: newSeoSlug,
              seoKeyword: newSeoKey,
              seoDescription: newSeoDescription,
              seoTitle: newSeoTitle,
              h1Tag: '',
              functionName: 'saveSeo',
            };
        
            apiCallingPost(seoPostData).then((data) => {
              if (data.data.success === '1') {
                alert('Saved Successfully!.');
                $('#seoSection').removeClass('editActive');
              } else {
                // console.log('error:seo:saveSeo:', data.data.errorMessage)
              }
            });
          };
        
          const roleName = window.sessionStorage.getItem('roleName');
        return (
            <div className='seo_main_div'>
                <div className="seo-main">
                    {/* <Helmet>
                    <meta name="title" content={this.state.seoTitle} />
                    <meta name="keyword" content={this.state.seoKeyword}></meta> 
                    <meta name="slug" content={this.state.seoSlug} ></meta>
                    <meta name="tag" content={this.state.h1Tag} ></meta>
                    <meta name="description" content={this.state.seoDescription}></meta> 
                </Helmet> */}
                    {roleName === "SuperAdmin" || roleName === "Admin" ?
                       <span className="seoTopButton" onClick={()=>seoViewClick()}>
                       SEO
                     </span>
                        : ""}
                    <div className="seoSection" id="seoSection">
                        <input type="text" autoComplete="off" className="editInput" maxLength={200} name="seoTitle" id="seoTitle" placeholder="SEO Meta Title" />
                        <textarea id="seoDecription" placeholder="SEO Meta Description" maxLength={355} name="seoDecription" className="editTextInput" />
                        <input type="text" autoComplete="off" className="editInput" name="seoKey" maxLength={250} id="seoKey" placeholder="SEO Meta Keywords" />
                        <h1 className="h1Class"></h1>
                        {/* <input type="text" autoComplete="off" className="editInput" name="Seoh1tag" maxLength={70} id="seoH1Tag" placeholder="SEO H1 Tag" /> */}
                        <input type="text" autoComplete="off" className="editInput" name="SeoSlug" maxLength={100} id="SeoSlug" placeholder="SEO Slug" />
                        <div className="editPanelButton">
                            <span className="saveDraft" title="Generate SEO" onClick={()=>generateSeo()}>
                            Generate
                            </span>
                            <span className="saveButton" title="Save" onClick={()=>saveSeo()}>
                            Submit
                            </span>
                            <span className="cancelButton" onClick={()=>seoCancel()}>
                            Cancel
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
export default LocalPageSeo