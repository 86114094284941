import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import AdminHeader from "./admin_header";
import "react-datepicker/dist/react-datepicker.css";
import CalImg from '../images/calender.png'
import Pagination from "react-js-pagination";
import * as XLSX from "xlsx";
import { apiCallingPost } from "../service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Reports() {
    const [activepage, SetActivepage] = useState(1)
    const [reportList, setReportList] = useState([])
    const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    const [totalItemsCount, setTotalItemsCount] = useState('')
    const [pageOffset, setPageOffset] = useState("0")
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const [searchValue, setSearchValue] = useState('');
    const [searchType, setSearchType] = useState(0);
    const [isEnabled, setIsEnabled] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isData ,setIsData] = useState(true);
    useEffect(() => {
        if (!window.sessionStorage.getItem("user_id")) {
            window.location.href = "/login"
        }
        getReport();
    }, [])

    const handleExportClick = () => {
        getReportAllData();
    };

    const getReportAllData = () => {
        let siteId = window.sessionStorage.getItem("username") ? window.sessionStorage.getItem("username") : ""
        if (window.sessionStorage.getItem("roleName") === "SuperAdmin") {
            siteId = ""
        }
        let postData = {
            functionName: "contactReports",
            siteId: siteId,
            offset: "0",
            max: "",
            fromDate: startDate ? formatDate(startDate) : '',
            toDate: endDate ? formatDate(endDate) : '',
            searchCategory: searchType,
            searchText: searchValue
        };
        // console.log("postData---", postData);
        apiCallingPost(postData).then((data) => {
            if (data.data.success === "1") {
                // console.log("contactResultReport---", data.data.result.contactResultReport);
                var dataArray = [];
                for (let i = 0; i < data.data.result.contactResultReport.length; i++) {
                    let obj = {};
                    obj.submittedOn = data.data.result.contactResultReport[i].submittedOn;
                    obj.storeCode = data.data.result.contactResultReport[i].storeCode;
                    obj.storeName = data.data.result.contactResultReport[i].storeName;
                    obj.locality = data.data.result.contactResultReport[i].locality;
                    obj.cityName = data.data.result.contactResultReport[i].cityName;
                    obj.stateName = data.data.result.contactResultReport[i].stateName;
                    obj.pinCode = data.data.result.contactResultReport[i].pinCode;
                    obj.name = data.data.result.contactResultReport[i].name;
                    obj.mobileNumber = data.data.result.contactResultReport[i].mobileNumber;
                    obj.emailId = data.data.result.contactResultReport[i].emailId;                    
                    obj.enquireType = data.data.result.contactResultReport[i].enquiryType;
                    obj.productCategoryName = data.data.result.contactResultReport[i].productCategoryName;
                    obj.productName = data.data.result.contactResultReport[i].productName;
                    obj.reason = data.data.result.contactResultReport[i].reason;
                    dataArray.push(obj);

                }
                let header = [
                    "Date",
                    "Store code",
                    "Store Name",
                    "Locality",
                    "City",
                    "State",
                    "Pin Code",
                    "Name",
                    "Mobile No.",
                    "Email ID",
                    "Enquiry Type",
                    "Product Category Name",
                    "Product Name",
                    "Comments"
                ];
                const columnWidths = [
                    { wch: 15 }, // Name
                    { wch: 12 }, // Mobile Number
                    { wch: 11 }, // Submitted On
                    { wch: 20 }, // Email Id
                    { wch: 20 }, // Enquire Type
                    { wch: 13 }, // State Name
                    { wch: 13 }, // City Name
                    { wch: 47 }, // Center Name
                    { wch: 19 }, // Product Category Name
                    { wch: 35 }, // Product Name
                    { wch: 25 }, // Comments
                ];
                const worksheet = XLSX.utils.json_to_sheet(dataArray);

                worksheet["!cols"] = columnWidths;
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "himalaya-enquiry-report");
                XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
                XLSX.writeFile(workbook, `himalaya-enquiry-report-${new Date(timestamp).toLocaleString('en-GB', { hour12: true }).replaceAll(",", "-").replaceAll("/", ".").replaceAll(":", ".")}.xlsx`, {
                    compression: true,
                });

            }
        });
    }
    const pageChange = (pageNumber) => {
        //console.log(pageNumber, "pageNumber")
        let offset = "0"
        SetActivepage(pageNumber)
        if (pageNumber == "1") {
            offset = "0";
        } else {
            offset = parseInt(pageNumber - 1) * itemsCountPerPage;
        }
        // SetActivepage(pageNumber)
        setPageOffset(offset)

        let siteId = window.sessionStorage.getItem("username") ? window.sessionStorage.getItem("username") : ""
        if (window.sessionStorage.getItem("roleName") === "SuperAdmin") {
            siteId = ""
        }
        if (startDate && endDate) {
            if (startDate > endDate) {
                toast.error(' End date must be greater than or equal to start date');
                document.getElementById('todate').focus();
                return false;
            }
        }
        const postData = {
            functionName: "contactReports",
            siteId: siteId,
            offset: offset,
            max: itemsCountPerPage,
            fromDate: startDate ? formatDate(startDate) : '',
            toDate: endDate ? formatDate(endDate) : '',
            searchCategory: searchType,
            searchText: searchValue

        };
        apiCallingPost(postData).then((data) => {
            if (data.data.success == "1") {
                setReportList(data.data.result.contactResultReport)
                // console.log('getUserAuthentication-- ', data.result)
                setTotalItemsCount(data.data.result.totalCount)
            }
        })

    };
    const getReport = () => {
        let siteId = window.sessionStorage.getItem("username") ? window.sessionStorage.getItem("username") : ""
        if (window.sessionStorage.getItem("roleName") === "SuperAdmin") {
            siteId = ""
        }
        let postData = {
            functionName: "contactReports",
            siteId: siteId,
            offset: pageOffset,
            max: itemsCountPerPage,
        };
        // console.log('getUserAuthentication-- ', postData)
        apiCallingPost(postData).then((data) => {
            if (data.data.success == "1") {
                setReportList(data.data.result.contactResultReport)
                // console.log('getUserAuthentication-- ', data.result)
                setTotalItemsCount(data.data.result.totalCount)
                setIsData(false);
            }
        })


    }


    const searchClickFunction = () => {
        SetActivepage(1)
        setPageOffset(0)
        let siteId = window.sessionStorage.getItem("username") ? window.sessionStorage.getItem("username") : ""
        if (window.sessionStorage.getItem("roleName") === "SuperAdmin") {
            siteId = ""
        }
        if (startDate && endDate) {
            if (startDate > endDate) {
                toast.error('End date must be greater than or equal to start date');
                document.getElementById('todate').focus();
                return false;
            }
        }
        const postData = {
            functionName: "contactReports",
            siteId: siteId,
            offset: "0",
            max: itemsCountPerPage,
            fromDate: startDate ? formatDate(startDate) : '',
            toDate: endDate ? formatDate(endDate) : '',
            searchCategory: searchType,
            searchText: searchValue

        };
        apiCallingPost(postData).then((data) => {
            if (data.data.success == "1") {
                setReportList(data.data.result.contactResultReport)
                // console.log('getUserAuthentication-- ', data.result)
                setTotalItemsCount(data.data.result.totalCount)
            }
        })
    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    const searchTypeOnChange = (event) => {
        if (event.target.value == 0) {
            setIsEnabled(true);
            setSearchType(event.target.value);
            setSearchValue('');
        } else {
            setSearchType(event.target.value);
            setIsEnabled(false);
        }
    };
    const searchValueFunction = (event) => {
        setSearchValue(event.target.value);
    };
    const clearSearch = () => {
        window.location.reload();
    };

    return (
        <div className="dashboard_wrapper">
            <ToastContainer />
            <AdminHeader />
            <div className="dash_breadcrumbs">
                <nav aria-label="breadcrumb">
                    <div className="container">
                        {(window.sessionStorage.getItem("roleName") === "SuperAdmin") &&
                            <ol className="breadcrumb px-0">
                                <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Enquiry Details</li>
                            </ol>
                        }
                    </div>
                </nav>
                <div className="container-fluid px-5">
                    <div className="heading_wrapper_div my-3">
                        <h1 className="view_reportsheading text-trnfrmReport">Enquiry Details</h1>
                        {totalItemsCount > 0 &&
                            <div className=" ml-auto">
                                <span><strong>Total Enquiries :</strong> {totalItemsCount}</span>
                                <button className="ml-4 input_form_bttn"
                                    onClick={handleExportClick}>
                                    Export Excel
                                </button>
                            </div>
                        }
                    </div>
                    <hr></hr>


                    <div className="row mb-3 bg-white mx-0 p-3">
                        <div className="col-md-2">
                            <select
                                className="inputField input-section p-0"
                                name="selectName"
                                value={searchType}
                                onChange={searchTypeOnChange}
                            >
                                <option value="0">Search In</option>
                                <option value="productCategory">Product Category</option>
                                <option value="productName">Product Name</option>
                                <option value="storeCode">Store Code</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <input
                                className="inputField input-section"
                                type="text"
                                value={searchValue}
                                disabled={isEnabled}
                                onChange={searchValueFunction}
                                name="searchText"
                                autoComplete="off"
                            />

                        </div>
                        <div className="col-md-2">
                            <DatePicker
                                className="inputField input-section"
                                selected={startDate}
                                placeholderText="From"
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => setStartDate(date)}
                                id="fromdate"
                            />
                        </div>
                        <div className="col-md-2">
                            <DatePicker
                                className="inputField input-section"
                                selected={endDate}
                                placeholderText="To"
                                calendarClassName="rasta-stripes"
                                dateFormat="dd-MM-yyyy"
                                onChange={(date) => setEndDate(date)}
                                id="todate"
                            />
                        </div>

                        <div className="col-md-1">
                            <button onClick={searchClickFunction} className="input_form_bttn">
                                Search
                            </button>
                        </div>
                        <div className="col-md-1 pl-0">
                            <button onClick={clearSearch} className="input_form_bttn">
                                Clear
                            </button>
                        </div>

                    </div>


                    <div className="react_pagination">
                        {totalItemsCount > itemsCountPerPage &&
                            <Pagination
                                activePage={activepage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={pageChange}
                            />
                        }
                    </div>
                    {isData === false && (
                    <div className="table-responsive mb-2 report_table_div bg-white">
                        {reportList.length > 0 ?
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sl. No.</th>
                                        <th>Date</th>
                                        <th>Store code</th>
                                        <th>Store Name</th>
                                        <th>Locality</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Pin Code</th>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                        <th>Email ID</th>
                                        <th>Enquiry Type</th>
                                        <th>product Category Name</th>
                                        <th>product Name</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {reportList.map((data, index) => (
                                        <tr key={index}>
                                            <td>{(pageOffset * 1) + index + 1}</td>
                                            <td>{data.submittedOn}</td>
                                            <td>{data.storeCode}</td>
                                            <td>{data.storeName}</td>
                                            <td>{data.locality}</td>
                                            <td>{data.cityName}</td>
                                            <td>{data.stateName}</td>
                                            <td>{data.pinCode}</td>
                                            <td>{data.name}</td>
                                            <td>{data.mobileNumber}</td>
                                            <td>{data.emailId}</td>
                                            <td>{data.enquiryType}</td>
                                            <td>{data.productCategoryName}</td>
                                            <td>{data.productName}</td>
                                            <td>{data.reason}</td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            </table>
                            :
                            <p className="text-danger list-nodata">Sorry, no enquiry details found</p>
                        }
                    </div>
                    )}
                    <div className="react_pagination">
                        {totalItemsCount > itemsCountPerPage &&
                            <Pagination
                                activePage={activepage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={pageChange}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reports;
