import React, { useState, useEffect } from "react";
// import DownArrow from '../images/downarrow.png'
import $ from 'jquery'
import moment from 'moment';
import { apiCallingPost } from "../service";
import { FiX } from "react-icons/fi";
function LocalPageEnquire(props) {

    const [productCategoryDetails, setProductCategoryDetails] = useState([])
    const [subProductDetails, setSubProductDetails] = useState([])
    const [subProductDetails1, setSubProductDetails1] = useState([])
    const [productCategoryName, setProductCategoryName] = useState("")
    const [productCategoryId, setProductCategoryId] = useState(0)
    const [productName, setProductName] = useState("")
    const [productId, setProductId] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [enquiryType, setEnquiryType] = useState("");

  
    useEffect(() => {
        getProductCategoryDetails();
    }, [props]);

    const getProductCategoryDetails = (e) => {
        var contactPost = {
            functionName: "getProductCategoryDetails",
        }
        apiCallingPost(contactPost).then((data) => {
            setProductCategoryDetails(data.data.result.productCategoryDetails)
            setSubProductDetails(data.data.result.productDetails)
        });
    }
    const productCategory = (Category) => {
        //setSubProductDetails([])
        //getProductCategoryDetails()
        let product = JSON.parse(Category)
        setProductCategoryName(product.productCategoryName)
        setProductCategoryId(product.productCategoryId)
        let chooseProduct = subProductDetails.filter((obj) => obj.productCategoryId === product.productCategoryId)
        setSubProductDetails1(chooseProduct)
    }
    const productNameFn = (productName) => {
        let product = JSON.parse(productName)
        setProductName(product.productName)
        setProductId(product.productId)

    }

    const formSubmit = (e) => {
        e.preventDefault();
        let flag = true
        var characters = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var mobile = /^\d{10}$/;
        let name = $("#txtCounsellingName").val();
        let email = $("#txtCounsellingEmail").val();
        let mobileNo = $("#txtCounsellingMobile").val();
        let reasonEnquire = $("#reasonEnquire").val();
        let siteId = props.siteData[0].siteId
        let currentUrl = window.location.href;

        if (name === "") {
            flag = false
            alert("Please enter full name.")
            $("#txtCounsellingName").focus()
        }
        else if (!mobileNo || mobile.test(mobileNo) === false) {
            flag = false
            alert("Please enter phone number.")
            $("#txtCounsellingMobile").focus()
            return
        }
        else if (mobileNo.length != 10 && (!mobileNo.match(/^[0-9]{10}$/))) {
            flag = false
            alert("Invalid phone number.")
            $("#txtCounsellingMobile").focus()
        }
        else if (!email) {
            flag = false
            alert("Please enter email id.")
            $("#txtCounsellingEmail").focus()
        }
        else if (!enquiryType) {          
            alert("Please Select Enquiry Type.")
            $("#txtCounsellingEnquiryType").focus()
        }
        else if (characters.test(email) == false) {
            flag = false
            alert("Invalid email Id.")
            $("#txtCounsellingEmail").focus()
        }
        else if (flag === true) {
            $("#submit-btn").prop('disabled', true);
            var contactPost = {
                name: name,
                emailId: email,
                mobileNumber: mobileNo,
                productId: productId,
                productCategoryId: productCategoryId,
                centerId: props.siteData[0].centerId,
                pageUrl: currentUrl,
                stateId: props.siteData[0].stateId,
                cityId: props.siteData[0].cityId,
                reason: reasonEnquire,
                siteId: siteId,
                enquiryType:enquiryType,
                functionName: "saveContactUs",
                "isCampaign": false,
                "submission_date": Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A'))
            }
            apiCallingPost(contactPost).then((data) => {
                if (data.data.success === "1") {
                    setShowModal(true)
                }
                $("#submit-btn").removeAttr("disabled");
            });

            // const requestData = {
            //     customer_name: name,
            //     phone: mobileNo,
            //     email_id: email,
            //     remarks: reasonEnquire,
            //     product_details: [
            //       {
            //         product_type: productCategoryName,
            //         product_name: productName,
            //       },
            //     ],
            // };
            // // Define the POST request options
            // const requestOptions = {
            // method: "POST",
            // headers: {
            //     "Content-Type": "application/json",
            //     // Add any other headers if needed
            // },
            // body: JSON.stringify(requestData),
            // };
            // // Define the API endpoint URL
            // const apiUrl = "https://himalayawellnesscompany.kapturecrm.com/add-update-enquiry";
            // // Send the POST request
            // fetch(apiUrl, requestOptions)
            // .then((response) => {
            //     if (!response.ok) {
            //     throw new Error(`HTTP error! Status: ${response.status}`);
            //     }
            //     return response.json();
            // })
            // .then((data) => {
            //     // Handle the response data here
            //     console.log("Response data:", data);
            //     // You can update state or perform other actions based on the response
            // })
            // .catch((error) => {
            //     // Handle any errors that occurred during the fetch
            //     console.error("Fetch error:", error);
            // });
        }
    }
    const modalCloseEnquiry = () => {
        setShowModal(false)
        $("#txtCounsellingName").val("");
        $("#txtCounsellingEmail").val("");
        $("#txtCounsellingMobile").val("");
        $("#councellingHighestQualification").focus().val("0");
        $("#cmbCounsellingCourse").val("").focus().val("0");
        $("#reasonEnquire").val("");
    }
    return (
        <>
            <div className="enquire_div">
                <div className="enquire_divpadding">
                    <input type="text" className="form_control" placeholder='Full Name' id="txtCounsellingName" />
                </div>
                <div className="enquire_divpadding">
                    <input type="email" className="form_control" placeholder='Email Address' id="txtCounsellingEmail" />
                </div>
                <div className="enquire_divpadding">
                    <input type="text" className="form_control" placeholder='Phone No' maxLength={10} id="txtCounsellingMobile" />
                </div>
                {(enquiryType !== "Product Enquiry" && enquiryType !== "Franchisee Enquiry") && (
                    <div className="enquire_divpadding">
                        <label htmlFor="txtCounsellingEnquiryType" className="sr-only">Choose a podcast:</label>
                        <select className='form_control' id="txtCounsellingEnquiryType"
                            value={enquiryType} onChange={(e) =>setEnquiryType(e.target.value)}>
                            <option value="">Choose Enquiry Type</option>
                            <option value="Product Enquiry">Product Enquiry</option>
                            <option value="Franchisee Enquiry">Franchisee Enquiry</option>
                        </select>
                    </div>
                )}
                {enquiryType === "Product Enquiry" && (
                    <div className="row product-data-show">
                        <div className="col-md-6">
                        <label htmlFor="txtCounsellingEnquiryType" className="sr-only">Choose a podcast:</label>
                            <select className='form_control' id="txtCounsellingEnquiryType"
                                value={enquiryType} onChange={(e) =>  setEnquiryType(e.target.value)}>
                                <option value="">Choose Enquiry Type</option>
                                <option value="Product Enquiry">Product Enquiry</option>
                                <option value="Franchisee Enquiry">Franchisee Enquiry</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <div className="enquire_divpadding">
                                <div className='position-relative'>
                                    <select className='form_control' id="councellingHighestQualification" 
                                    onChange={(e) => { productCategory(e.target.value) }} >
                                        <option value={"0"}>Choose Product Category</option>
                                        {productCategoryDetails.map((data, index) => (
                                            <option value={JSON.stringify({ productCategoryId: data.productCategoryId, productCategoryName: data.productCategoryName })} key={index}>{data.productCategoryName}</option>
                                        ))
                                        }                        </select>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="enquire_divpadding">
                                <div className='position-relative'>
                                    <select className='form_control' id="cmbCounsellingCourse" onChange={(e) => { productNameFn(e.target.value) }}>
                                        <option value={"0"}>Choose Product</option>
                                        {subProductDetails1.map((data, index) => (
                                            <option value={JSON.stringify({ productId: data.productId, productName: data.productName })} key={index}>{data.productName}</option>
                                        ))}
                                    </select>
                                    {/* <img src={DownArrow} alt="arrow" className='updown_arrowstyle' /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {enquiryType === "Franchisee Enquiry" && (
                    <div className="row product-data-show enquire_divpadding">
                        <div className="col-md-6">
                        <label htmlFor="txtCounsellingEnquiryType" className="sr-only">Choose a podcast:</label>
                            <select className='form_control' id="txtCounsellingEnquiryType"
                                value={enquiryType} onChange={(e) =>  setEnquiryType(e.target.value)}>
                                <option value="">Choose Enquiry Type</option>
                                <option value="Product Enquiry">Product Enquiry</option>
                                <option value="Franchisee Enquiry">Franchisee Enquiry</option>
                            </select>
                        </div>
                        <div className="col-md-6 px-0">
                            <div className="enquire_divpadding">
                                <div className="form-control border-0">
                                    Submit the enquiry form and we will reach out to you shortly.*
                                </div>
                            </div>
                        </div>

                    </div>
                )}

                <div className="enquire_divpadding">
                    <div className="position-relative">

                        <textarea id="reasonEnquire" cols="20" rows="3" className="form_control textarea" placeholder="Write comments..." spellCheck="false"></textarea>
                    </div>
                </div>
                <div className='row m-0'>
                    <div className="col-md-12 ml-auto px-0">
                        <button type='button' className='button-theme' id="submit-btn" onClick={formSubmit}>Send Enquiry</button>
                    </div>
                </div>
            </div>
            {showModal &&
                <div className="enquiry_popup">
                    <div className="enquiry_innerpopyp">
                        <div className="enquiry_innerbody">
                            <FiX className="enquiry_inner_close" onClick={() => modalCloseEnquiry()} />
                            <div className="">Thank You for your enquiry!!!</div>
                            <button type="button" className="enquiry_close_btn" onClick={() => modalCloseEnquiry()}>Ok</button>
                        </div>
                        {/* <div className="enquiry_innerfooter">
                
               </div> */}
                    </div>
                </div>
            }
        </>
    );
}

export default LocalPageEnquire;
