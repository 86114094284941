import React from "react";
import FooterMenu from "../himalaya/footer_menu";
function Footer() {
    return (
      <div className="main_footer">
        <FooterMenu/>
        <div className="footer_bottom">
          <div className="col-md-12 col-xl-10 mx-auto">
            <div className="main_footer_desktop_menu">
            <ul className="nav ">
              <li className="nav-item"><span className="himalaya_company">© {new Date().getFullYear()} Himalaya Wellness Company. All Rights Reserved.</span></li>
            </ul>
            </div>          
            <p className="mobile_menu">{new Date().getFullYear()} Himalaya Wellness Company. All Rights Reserved.</p>
            <p className="mobile_menu_infor">Information on this website is provided for informational purposes and is not a substitute for advice from your own physician or other medical professionals.This website is meant for use by Indian residents only. The product pack shots on the website are for representation purposes only.The actual product may have slight variations in appearance and price.</p>
        </div>
        </div>
      </div>
    );
  }
  
  export default Footer;
  