import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import AdminHeader from "./admin_header";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import { FaSearch } from "react-icons/fa";
import Slider from '../images/slider.png'
import EditImg from '../images/edit-icon01.png'
import SaveImg from '../images/save.png'
import { apiCallingPost } from "../service";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoading from '../images/loading-cms.gif'
let offset = "0"

function Listing() {
  const [siteList, setSiteList] = useState([])
  const [activepage, SetActivepage] = useState(1)
  const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [pageOffset, setPageOffset] = useState("0")
  const [searchText, setSearchText] = useState("")
  const [searchTextFlag, setSearchTextFlag] = useState(false)
  const [exPortData, setExPortData] = useState([])
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!window.sessionStorage.getItem("user_id")) {
      window.location.href = "/login"
    }
    getCenterList("");
  }, [])

  const getCenterList = (searchKeyWord) => {
    let postData = {
      functionName: "viewListing",
      userId: window.sessionStorage.getItem("user_id"),
      searchKeyword: searchKeyWord.replaceAll(", ", "_"),
      offset: offset,
      max: itemsCountPerPage,
    };
    apiCallingPost(postData).then((data) => {
      if (data.data.success == "1") {
        setSiteList(data.data.result.siteList)
        setTotalItemsCount(data.data.result.totalCount)
        setLoading(false);
        let postData = {
          functionName: "exportSiteData",
          searchText: searchKeyWord.replaceAll(", ", "_")
        }
        apiCallingPost(postData).then((data) => {
          if (data.data.success == "1") {
            setExPortData(data.data.result)
          }
          else {
            setExPortData([])
          }
        })
      }
      else {
        setSiteList([])
        setTotalItemsCount('0')
      }
    })

  }
  const handlePageChange = (pageNumber) => {
    SetActivepage(pageNumber)
    var max;
    if (pageNumber === "1") {
      offset = "0";
    } else {
      offset = parseInt(pageNumber - 1) * itemsCountPerPage;
    }
    SetActivepage(pageNumber)
    setPageOffset(offset)
    getCenterList(searchText);
  };
  const searchHereDetails = () => {
    var search = document.getElementById("searchHere");
    search = search.value.toLowerCase();
    setSearchText(search)
    setSearchTextFlag(true)
    offset = "0"
    if (document.getElementById("searchHere").value != "") {
      getCenterList(search);
    }
    else {
    }
  }
  const allSearchDetails = () => {
    var search = document.getElementById("searchHere");
    search = search.value.toLowerCase();
    setSearchText(search)
    setSearchTextFlag(true)
    offset = "0"
    if (document.getElementById("searchHere").value === "") {
      getCenterList(search);
    }
  }
  const ExportAllData = () => {
    if (exPortData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(exPortData);
      const columnWidths = [
        { wch: 8 }, // Width for "Store Code"
        { wch: 20 }, // Width for "Business Name"
        { wch: 28 }, // Width for "Complete Address"
        { wch: 16 }, // Width for "Locality"
        { wch: 16 }, // Width for "City"
        { wch: 16 }, // Width for "State"
        { wch: 10 }, // Width for "Pin Code"
        { wch: 12 }, // Width for "Latitude"
        { wch: 12 }, // Width for "Longitude"
        { wch: 15 }, // Width for "Contact Number"
        { wch: 24 }, // Width for "Operational Hours"
        { wch: 20 }, // Width for "GBP Links"
        { wch: 20 }, // Width for "Review URL"
        { wch: 30 }, // Width for "Location Page URL"
      ];
      // Set column widths in the worksheet
      worksheet['!cols'] = columnWidths;
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const filename = 'data.xlsx';
      saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), filename);
    }
    else {
      toast.error("Store list is empty!")
    }
  }
  const viewPage = (store_code) => {
    window.sessionStorage.setItem("store_code", store_code)
    window.location.href = "/add-store"
    window.sessionStorage.removeItem("addFlag");
  }

  return (
    <div className="dashboard_wrapper">
      <ToastContainer />
      <AdminHeader />
      <div className="dash_breadcrumbs">
        <nav aria-label="breadcrumb">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
              <li className="breadcrumb-item active" aria-current="page">Store Details</li>
            </ol>
          </div>
        </nav>
        <div className="container">
          <div className="heading_wrapper_div">
            <h1 className="view_reportsheading">Store Details</h1>
            <div className="cms_search row mx-0">
              <div className="cms_input_div">
                <div className="position-relative w-100">
                  <input type="text" className="cms_searchinput " id="searchHere" placeholder="Search" onKeyUp={(e) => { allSearchDetails() }} />
                  <FaSearch className="cms_searchicon" />
                </div>
                <div className="input-group-append">
                  <span className="input-group-text" onClick={(e) => { searchHereDetails() }} id="searchHere" >Search</span>
                </div>
              </div>
              <button className="btn btn-warning ml-3 py-2" onClick={(e) => { ExportAllData() }} >Export</button>
            </div>
          </div>
          <div className="react_pagination">
            {totalItemsCount > itemsCountPerPage &&
              <Pagination
                activePage={activepage}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={totalItemsCount}
                pageRangeDisplayed={totalItemsCount / itemsCountPerPage}
                onChange={handlePageChange}
              />
            }
          </div>
          <div className="cms_dashboad_div">

          {loading ? (
        <div className="loder-list">
          <img src={ImageLoading} alt="loader" width="150" height="auto" className="loder-list-img"/>
        </div>
      ) : siteList.length > 0 ? (
        siteList.map((data, i) => (
          <div key={i} className="inner_cms_div">
            <div className="inner_cms_div_connt">
              <div className="inner_cms_div_left">
                <h2 className="inner_cms_divhead">{data.centerName.replaceAll('_', ', ')}</h2>
                <p className="d-flex">
                  <span className="inner_cms_siteId">{"Site Id - " + data.siteId}</span>
                  <span className="inner_cms_siteId inner_cms_siteCode ml-2">{"Store Code - " + data.store_code}</span>
                </p>
                <p className="inner_cms_divpara"> {data.centreAddress + ", " + data.cityName + ", " + data.pinCode}</p>
              </div>
              <div className="inner_cms_div_right ">
              <img src={EditImg} className="inner_cms_div_imgdiv" alt="edit" width="32" height="32"
              onClick={(e) => viewPage(data.store_code)}
              />
                {/* <div className="inner_cms_div_img">
                  <span
                    onClick={(e) => viewPage(data.store_code)}
                    className="locator_cms_ancher">
                    <img src={EditImg} className="inner_cms_div_imgdiv" alt="edit" />
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-danger">No data found</p>
      )}
          </div>
          <div className="react_pagination">
            {/* <Pagination
            activePage={activepage}
            itemsCountPerPage={5}
            totalItemsCount={450}
            pageRangeDisplayed={5}
            onChange={()=> pageChange()}
        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Listing;
