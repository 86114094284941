import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import Header from "../layout/header";
import LocalPageBanner from "./local_page_banner";
import Footer from "../layout/footer";
import LocalPageCentreDetail from "./local_page_centre_detail";
import LocalPageEnquire from "./local_page_enquire";
import AdminHeader from "../cms/admin_header";
import { apiCallingPost } from "../service";
import $ from 'jquery'
import LocalPageServices from "./local_page_services";
import LocalPageServicesSub from "./local_page_services_sub";
import LocalPageDirection from "./local_page_direction-iframe";
import LocalPageReview from "./local_page_review";
import LocalPageSeo from "../cms/local_page_seo";
import HeaderSocialLinks from "./headerSociallink";
import LocalPageTags from "./local_page_tags";
import LocalPageYoutube from "./local_page_youtube";
import LocalPagePodcasts from "./local_page_podcasts";
import LocalPageBlog from "./local_page_blog";
import LocalPageSocialIcons from "./local_page_social_icons";
import ImageLoader from "react-load-image";
import ProdDef from '../images/slider-white.jpg'
import PageNotFoundSub from "./page-not-found-sub";
import AWS from "aws-sdk";
var s3;
function LocalPage(props) {
  const params = useParams()
  const [getDataFlag, setGetDataFlag] = useState(false)
  const [bannerArray, setBannerArray] = useState([])
  const [bannerPath, setBannerPath] = useState([])
  const [bannerContentDataId, setBannerContentDataId] = useState("")
  const [bannerContentDataSubId, setBannerContentDataSubId] = useState("")
  const [centreDetails, setCentreDetails] = useState([])
  const [centreDetailsContentDataId, setCentreDetailsContentDataId] = useState("")
  const [centreDetailsContentDataSubId, setCentreDetailsContentDataSubId] = useState("")
  const [servicesDetails, setServicesDetails] = useState([])
  const [servicesDetailsContentDataId, setServicesDetailsContentDataId] = useState("")
  const [siteData, setSiteData] = useState([])
  const [editMode, setEditMode] = useState("")
  const [allowSocialMediaEdit, setAllowSocialMediaEdit] = useState(0)
  const [contactNo, setContactNo] = useState("")
  const [facebook, setFacebook] = useState("")
  const [instagram, setInstagram] = useState("")
  const [twitter, setTwitter] = useState("")
  const [youtube, setYoutube] = useState("")
  const [linkedin, setLinkedin] = useState("")
  const [gTag, setGTag] = useState("")
  const [ga, setGa] = useState("")
  const [fbTracking, setFbTracking] = useState("")
  const [servicesDetailsContentDataSubId, setServicesDetailsContentDataSubId] = useState("")

  useEffect(() => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    window.sessionStorage.setItem("isLogedIn", "0")
    getData();
    // console.log("user_id=", window.sessionStorage.getItem("user_id"));
  }, [props]);

  useEffect(() => {
  }, [siteData])
  

  const getData = () => {
    // if (props.match.params.displayName != "locatecenter" || props.match.params.displayName != "login" || props.match.params.displayName != "listing" || props.match.params.displayName != "smsReport" || props.match.params.displayName != "contactReport" || props.match.params.displayName != "campaign") {
    let userRole = '';
    if (window.sessionStorage.getItem('roleName')) {
      userRole = window.sessionStorage.getItem('roleName');
    }
    let siteId = "";
    let url = "";
    // console.log("params----", params.url);
    if (params.url) {
      url = params.url
      window.sessionStorage.setItem("url", url);
    }
    let editMode;
    if (window.sessionStorage.getItem("user_id")) {
      editMode = "edit";
      setEditMode(editMode)
    } else {
      editMode = "view";
      setEditMode(editMode)
    }
    let roleName = ""
    if (window.sessionStorage.getItem("roleName")) {
      roleName = window.sessionStorage.getItem("roleName")
    }
    let postData = {
      functionName: "contentData",
      siteId: siteId,
      editMode: editMode,
      editId: "",
      displayName: "",
      userRole: userRole,
      pageId: "1",
      centreName: "",
      url: url
    };
    //console.log("postData.....", postData);
    apiCallingPost(postData).then((data) => {
      //console.log("ppppppppppppppppppppppppppppppppppp", data);
      if (data.data.success === "1") {
        if(Object.keys(data.data.result).length !== 0) {
          let siteData = data.data.result.siteData;

          let bannerArray = data.data.result.bannerData[0].banner;
          let bannerPath = data.data.result.bannerData[0].bannerPath;

          let centreDetails = data.data.result.contentList.filter(
            (sectionObject) => sectionObject.contentSectionName === "Centre Details"
          );
          let servicesDetails = data.data.result.contentList.filter(
            (sectionObject) => sectionObject.contentSectionName === "Services"
          );
          // console.log("-----servicesDetails---------", servicesDetails);
          setGetDataFlag(true)
          setSiteData(siteData)
          setBannerArray(bannerArray)
          setBannerPath(bannerPath)
          setBannerContentDataId(bannerArray[0].contentDataId)
          setCentreDetails(centreDetails[0].listing)
          setCentreDetailsContentDataId(centreDetails[0].contentDataId)
          setCentreDetailsContentDataSubId(centreDetails[0].listing.length > 0 ? centreDetails[0].listing[0].contentDataSubId : "")
          setServicesDetails(servicesDetails[0].listing)
          setServicesDetailsContentDataId(servicesDetails[0].contentDataId)
          setServicesDetailsContentDataSubId(servicesDetails[0].listing.length > 0 ? servicesDetails[0].listing[0].contentDataSubId : "")
          setAllowSocialMediaEdit(siteData[0].enableSocialMedia)
          setContactNo(siteData[0].contactNo)
          setFacebook(siteData[0].facebook)
          setInstagram(siteData[0].instagram)
          setLinkedin(siteData[0].linkedIn)
          setTwitter(siteData[0].twitter)
          setYoutube(siteData[0].youtube)
          setFbTracking(siteData[0].fbTracking)
          setGTag(siteData[0].gTag)
          setGa(siteData[0].ga)
        }
        else {
          setSiteData(null)
        }
      }
      else {
        setSiteData(null)
      }

    })
    // console.log("props.bannerArray 1=", bannerArray);
    //}
  }

  const rejectData = (contentDataSubId, comment) => {
    const postData = {
      functionName: "rejected",
      contentDataSubId: contentDataSubId,
      siteId: siteData[0].siteId,
      rejectComment: comment
    }
    if (window.confirm("Do you want to Reject?")) {
      apiCallingPost(postData).then((data) => {
        if (data.data.success == 1) {
          getData();
        }
      })
    }
    else {
      return false;
    }

  }
  const approveClick = (contentDataId) => {
    const postData = {
      functionName: "approveNewDetails",
      siteId: "",
      contentDataId: contentDataId,
      singleApprove: 'true'
    }
    // console.log(postData)
    if (window.confirm("Do you want to Approve?")) {
      apiCallingPost(postData).then((data) => {
        if (data.data.success == 1) {
          alert("Approved successfully");
          getData();
        }
      })
    }
    else {
      return false;
    }
  }
  const saveAllForms = (e) => {
    const postData = {
      functionName: "submitAll",
      siteId: siteData[0].siteId,
      contentDataId: "",
      singleApprove: 'false'
    }
    // console.log("saveAllForms  ", postData)
    apiCallingPost(postData).then((data) => {
      if (data.data.success == "1") {
        // console.log("Saved successfully  ", data.data)
        alert("Saved successfully");
        //window.location.reload();
      }
    })
  }
  const logout = () => {
    window.sessionStorage.setItem("user_id", "");
    window.sessionStorage.setItem("roleName", "");
    window.sessionStorage.setItem("isLogedIn", "")
    //window.sessionStorage.setItem("dealerName", "");
    //window.sessionStorage.setItem("url", "");
    //window.sessionStorage.setItem("contactNo", "");
  }

  const Preloader = () => {
    return <img src={ProdDef} alt="icon" className="bannerItemImg"></img>
  }

  return (
    <div className={"main_wrapper " +
      (window.sessionStorage.getItem("isLogedIn") === "1" ? "main_wrapper_cms" : "")}>
      {window.sessionStorage.getItem("isLogedIn") === "1" ?
        <AdminHeader />
        :
        <Header />
      }
      {((window.sessionStorage.getItem('roleName') === "Admin" || window.sessionStorage.getItem('roleName') === "SuperAdmin") && (window.sessionStorage.getItem('isLogedIn') === "1")) &&

        <>
          <div className="editNav">
            <Link className="backLink"
              to={window.sessionStorage.getItem('roleName') === "SuperAdmin" ? "/cms" : "/enquire-details"}
            >Back</Link>
          </div>
          {/* <div className="header_social_div">
            <span className="saveButton" title="Submit All" id="saveAll" onClick={(e) => {saveAllForms(e) }}>Submit</span>
                <span className="saveButton" id="saveAll" title="Cancel All" onClick={(e) => { window.location.reload(); }}>Cancel</span>
          </div> */}
          <HeaderSocialLinks
            getData={getData}
            siteData={siteData}
            allowSocialMediaEdit={allowSocialMediaEdit}
            fbTracking={fbTracking}
            ga={ga} gTag={gTag}
            contactNo={contactNo}
            facebook={facebook}
            instagram={instagram}
            twitter={twitter}
            youtube={youtube}
            linkedin={linkedin} />
        </>
      }
      {siteData != null ?
        <div className="main_container">
          <div className={bannerArray == [] ? "main_banner main_banner_height" : "main_banner"}>
            {getDataFlag === true ?
              <LocalPageBanner
                getData={getData}
                bannerArray={bannerArray}
                bannerPath={bannerPath}
                bannerContentDataId={bannerContentDataId}
                bannerContentDataSubId={bannerContentDataSubId}
              />
              :
              <div className="row">
                <div className="main_banner_list">
                  <ImageLoader src={ProdDef} >
                    <img alt="Error" />
                    <div>Error!</div>
                    <Preloader />
                  </ImageLoader>
                </div>
              </div>
            }
          </div>
          {window.sessionStorage.getItem('isLogedIn') === "1" &&
            <LocalPageSeo
              getData={getData}
              siteData={siteData}
            // defaultSeoData={defaultSeoData}
            // seoData={seoData}
            />
          }

          {getDataFlag === true ?
            <div className="locator_main_div">
              <div className="col-md-12 main-wrapper mx-auto">
                <div className="row  locator_himalaya">
                  <div className="col-xl-6 col-lg-7 locator_row_right">
                    <div className="locator_row_listitem">
                      <LocalPageCentreDetail
                        getData={getData}
                        rejectData={rejectData}
                        approveClick={approveClick}
                        centreDetails={centreDetails}
                        siteData={siteData}
                        centreDetailsContentDataId={centreDetailsContentDataId}
                        centreDetailsContentDataSubId={centreDetailsContentDataSubId}
                      />
                      {/* <div className="locate_responseimg">
                              <img src={Img} className="locator_listimg"/>
                              </div> */}
                      {/* <LocalPageServices
                    getData={getData}
                    rejectData={rejectData}
                    approveClick={approveClick}
                    servicesDetails={servicesDetails}
                    servicesDetailsContentDataId={servicesDetailsContentDataId}
                    servicesDetailsContentDataSubId = {servicesDetailsContentDataSubId}
                  /> */}
                      <LocalPageServicesSub />
                    </div>
                  </div>
                  <div className="col-md-12 gmap_locator_head">
                    <h2 className="gmap_locator_heading">Directions</h2>
                  </div>
                  <div className="col-xl-6 col-lg-5 gmap_locator_row_left">
                    <div className="gmap_wrapper">
                      <LocalPageDirection
                        getData={getData}
                        siteData={siteData}
                        centreDetails={centreDetails}
                      />
                    </div>
                  </div>
                  <hr className="location_direction_border" />
                </div>
                <div className="row">
                  <LocalPageReview
                    siteData={siteData} />
                  <div className="col-lg-6 enquiry_padding">
                    <h2 className="main-head">Enquiry</h2>
                    <LocalPageEnquire
                      siteData={siteData}
                    />
                  </div>
                </div>
                <div className="row">
                  <LocalPagePodcasts />
                </div>
                <div className="row">
                  <LocalPageBlog />
                </div>
                <div className="row">
                  <LocalPageYoutube
                  />
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-7 tags_div">
                    <LocalPageTags
                      siteData={siteData}
                    />
                  </div>
                  <div className="col-md-12 col-lg-5 social_div">
                    <LocalPageSocialIcons
                      siteData={siteData}
                    />
                  </div>
                </div>

              </div>
            </div>
            : ""
          }
        </div>
        : siteData == null &&
        <PageNotFoundSub />
      }
      <Footer />

    </div>
  );
}

export default LocalPage;
