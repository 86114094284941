import React, { useState, useEffect }  from "react";
function DashboardList() {
  useEffect(() => {
    if (!window.sessionStorage.getItem("user_id")) {
        window.location.href = "/login"
    }
  }, [])
  const AddPage = () => {
    window.sessionStorage.setItem("addFlag", "addFlag");
  }
  return (
    <div className="dashboard_listwrapper">
        <div className="container">
            <ul className="nav nav_ul">
              {(window.sessionStorage.getItem("roleName") === "SuperAdmin" || window.sessionStorage.getItem("roleName") === "Admin") &&
                <li><a href="/store-details" className="dahsbaord_listitem">Store Details</a></li>
              }
              <li><a href="/enquire-details" className="dahsbaord_listitem">Enquire Details</a></li>
              <li><a href="/add-store" className="dahsbaord_listitem"
              onClick={(e) => AddPage()}
              >Add Store</a></li>
              <li><a href="/file-upload" className="dahsbaord_listitem">File Upload</a></li>
              <li><a href="/banner-image-upload" className="dahsbaord_listitem">Banner Image Upload</a></li>
            </ul>
        </div>
    </div>
  );
}

export default DashboardList;
