import React, { useState, useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import PageNotFoundSub from "./page-not-found-sub";

function PageNotFound() {
    return (
        <div className={"main_wrapper "}>
            <Header />
                <PageNotFoundSub />
            <Footer></Footer>
        </div>
    );
}

export default PageNotFound;
