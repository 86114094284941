import React from 'react';
function LocalPageDirection(props)  {
    return (
        <iframe className="map-iframe-wrapper"
          width="100%"
          height="100%"
          title="map"
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=${props.siteData[0].latitude},${props.siteData[0].longitude}&zoom=16` } allowFullScreen>
        </iframe>

    );
}
export default LocalPageDirection;
