import React, { useState, useEffect } from "react";

function PageNotFoundSub() {
    return (
    <div className="container text-center p-5 my-4" >
        <h1 className="error-head">Oops! 404 Error</h1>
        <p class="error-lead py-3">We can't seem to find the page your looking for.</p>
        <p>
            <a href="/" className="view_location_navg px-3 d-inline">Go to Homepage</a>
        </p>
    </div>
    );
}

export default PageNotFoundSub;
