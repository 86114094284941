import React, { useState, useEffect } from 'react';
import "../css/slick.css"
import "../css/slick-theme.css"
import Slider from "react-slick";
import { apiCallingPost } from "../service";

function LocalPageBlog() {
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    getBlogDataFn();
  }, []);

  const getBlogDataFn = () => {
    let postData = {
      functionName: "getBlogs",
      blogsId: "",
      isActive: "true",
      offset: 0,
      max: ""
    };
    apiCallingPost(postData).then((data) => {
      // console.log("blog----", data.data.result.blogsDetails);
      if(data.data.success = "1"){
        setBlogList(data.data.result.blogsDetails);
      }
    })
  }

    var settingsYoutube = {
      infinite: false,
      speed: 500,
      dots: false,
      swipeToSlide: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
  return (
      blogList.length > 0 &&
        <div className="col-md-12 blog-wrapper">
          <h2 className="main-head">Blogs </h2>
          <div className="blog-section">
          <Slider {...settingsYoutube}>
          {blogList.map(data => (
              <div className="blog-subList" 
              key={data.blogsId}
              >
                <a className="blog-subList-a"  href={data.blogLink}>
                  <div className="blog-imgdiv">
                    <img className="blog-img img-fluid"
                    alt="blog"                    
                    src={data.blogImage} width="800" height="400"/>                    
                    {/* <p className="blog-post-det"> */}
                      {/* <span className="blog-post-det-date">{data.createdOn}</span> */}
                      <span className="blog-post-det-date">{data.dateTime}</span>
                      {/* <span className="blog-post-det-time ml-1">8.33 am</span> */}
                      {/* <span className="blog-post-det-readtime ml-2">2 minute read</span> */}
                    {/* </p> */}
                  </div>
                  <div className="blog-content">
                    <h3 className="blog-head"> {data.blogTitle} </h3>
                    <h4 className="blog-subhead"> {data.blogShortDescription} </h4>
                    <p className="blog-text"> {data.blogLongDescription} </p>
                  </div>
                </a>
              </div>
            ))} 
            </Slider>
          </div>
        </div>
  );
}

export default LocalPageBlog;
