import React, { useState, useEffect, useRef } from "react";
import ReactStars from "react-rating-stars-component";
import { getGoogleReviewDetails } from "../service";
import moment from "moment";
import { AiTwotoneStar } from "react-icons/ai";
import StarEmpty from '../images/star_empty.png'
import StarFull from '../images/star_full.png'
function LocalPageReview(props) {
    const [reviewList, setReviewList] = useState([])
    const sliderRef = useRef(null);
    useEffect(() => {
        getReviews();
    }, [props]);

    const getReviews = () => {
        let postData = {
            functionName: "getReviewUsingStoreCode",
            storeCode: props.siteData[0].centerId,
            account_id: "115844776645416812845"
        }
        getGoogleReviewDetails(postData).then((data) => {
            if (data.data.success === "1") {
                // console.log("data-------", data.data.data.locationReviews);
                let locationReviews = data.data.data.locationReviews
                //console.log("locationReviews.length----", data.data.data.locationReviews)
                if (data.data.data.locationReviews) {
                    locationReviews = locationReviews.filter((obj) => ((obj.review.starRating === "FIVE" || obj.review.starRating === "FOUR") && obj.review.comment))
                    setReviewList(locationReviews)
                }

            }
        })
    }

    const next = () => {
        sliderRef.current.slickNext();
    }
    const previous = () => {
        sliderRef.current.slickPrev();
    }
    return (
        <>
            {reviewList.length > 0 ?
                <div className="col-lg-6 mb-5 reviews_div">
                    <h2 className="main-head">Reviews</h2>
                    <div className="reviews_mainWrapper">
                        <div className="reviews_mainWrapperinner">
                            {reviewList.map((data, index) => (
                                <div key={index} className={"reviews_reviewBox_inner " + (reviewList.length === 1 && 'reviewfull')}>
                                    <div className="reviews_reviewBox">
                                        <div className="reviews_imageText">
                                            <div className="reviews_imageTextDiv">
                                                <div className="reviews_imgreview">
                                                    <img src={data.review.reviewer.profilePhotoUrl} alt="User" width="120" height="120" className="img-fluid"/>
                                                </div>
                                                <span className="reviews_imageDate">{data.review.reviewer.displayName}</span>
                                            </div>
                                            <div className="reviews_starRate">
                                                <ReactStars
                                                    count={5}
                                                    // onChange={ratingChanged}
                                                    size={24}
                                                    value={
                                                        data.review.starRating === "ONE"
                                                            ? 1
                                                            : data.review.starRating === "TWO"
                                                                ? 2
                                                                : data.review.starRating === "THREE"
                                                                    ? 3
                                                                    : data.review.starRating === "FOUR"
                                                                        ? 4
                                                                        : data.review.starRating === "FIVE"
                                                                            ? 5
                                                                            : 0

                                                    }
                                                    activeColor="#FFAC33" color='#CEC9C1' edit={false}
                                                    // char={<AiTwotoneStar />}
                                                    emptyIcon={<img src={StarEmpty} alt="StarEmpty"  width={20} height={20}/>}
                                                    filledIcon={<img src={StarFull} alt="StarFull" width={20} height={20}/>}
                                                />
                                            </div>
                                        </div>
                                        <p>{data.review.comment}</p>
                                        <small>{moment(data.review.createTime).format("DD MMMM YYYY")}</small>
                                    </div>
                                    {/* </div> */}
                                </div>
                            ))}
                        </div>
                    </div>
                    {props.siteData[0].reviewLinks &&
                        <div className="col-lg-12 col-md-4 col-7 ml-lg-0 ml-auto px-0">
                            <a href={props.siteData[0].reviewLinks} target="_blank" className="button-theme">Submit Review</a>
                        </div>
                    }
                </div>
                :
                <div className="col-lg-6 mb-5 reviews_div">
                    <h2 className="main-head">Reviews</h2>
                    <div className="reviews_mainWrapper reviews_empty_reviews">
                        <div className="reviews_empty_reviews_para">Be the first one to review this location !</div>
                        {props.siteData[0].reviewLinks &&
                        <div className="col-lg-12 col-md-4 col-7 ml-lg-0 ml-auto px-0">
                            <a href={props.siteData[0].reviewLinks} target="_blank" className="button-theme">Submit Review</a>
                        </div>
                        }
                    </div>
                </div>

            }
            {/* <div className="col-lg-6 mb-5 reviews_div">
                    <div className="reviews_reviewBox">
                        <div className="imageText">
                        <div className="imageTextDiv">
                            <div className="imgreview">
                                <img src={User} alt="User" />                   
                            </div>
                            <span className="imageDate">Akira Sharam</span>
                            </div>
                            <div className="starRate">
                            <ReactStars
                        count={5}
                        // onChange={ratingChanged}
                        size={24}
                        value={4.5}
                        activeColor="#FFAC33" color='#CEC9C1' edit={false}
                        char={<AiTwotoneStar/>}
                        half={true}
                        />   
                            </div>
                        </div>
                        <p>Himalaya is a leading global herbal health and personal care organization with close to 500 products in over 100 countries.</p>
                        <small>18 August 2022</small>
                    </div>
                    </div> */}
        </>
    );
}

export default LocalPageReview;
