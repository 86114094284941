import React, { useState, useEffect } from "react";
 import '../css/edit_style.css'
// import '../css/edit_style.min.css'
import Logo from '../images/logo.png'
import { apiCallingPost } from "../service";

function Login() {
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [loginError, setloginError] = useState("")
    const [loginErrorFlag, setloginErrorFlag] = useState(false)

    useEffect(() => {
    }, [])

    const setLoginHandler = (e) => {
        if (userName === "") {
            setloginError("Username required")
            setloginErrorFlag(true)
        }
        else if (password === "") {
            setloginError("Password required")
            setloginErrorFlag(true)
        }
        else {
            setloginErrorFlag(false)
            let postData = {
                functionName: "login",
                username: userName,
                password: password
            };
            apiCallingPost(postData).then((data) => {
                // console.log('getUserAuthentication-- ', data)
                if (data.data.success == "1") { 
                    window.sessionStorage.setItem("user_id", data.data.result.userData[0].userId)
                    window.sessionStorage.setItem("username", data.data.result.userData[0].username)
                    window.sessionStorage.setItem("roleName", data.data.result.userData[0].roleName)
                    if (data.data.result.userData[0].roleName == "Admin" || data.data.result.userData[0].roleName == "SuperAdmin") {
                        window.sessionStorage.setItem("isLogedIn", "1")
                        // window.sessionStorage.setItem("isEditor", data.data.result.userData[0].isEditor)
                        if (data.data.result.userData[0].roleName == "Admin") {
                             window.location.href = '/enquire-details'
                        }
                        else {
                            window.location.href = '/dashboard'
                        }
                    }
                    else {
                        window.location.href = '/login'
                        window.sessionStorage.setItem("isLogedIn", "0")
                    }
                }
                else {
                    setloginError("Username Or Password Is InValid.")
                    setloginErrorFlag(true)
                }
            })
        }
    }
    return (
        <div className='login_wrapper'>
            <div className='left_login'>
                <div className='login_logo'>
                    <img src={Logo} className="img_logo" alt="logo" />
                </div>
                {/* <h1 className='login_header'>Welcome! At <br /> Himalaya Dashboard</h1> */}
                <h1 className='login_header'>Welcome to the Himalaya Hyperlocal Content Management System</h1>
            </div>
            <div className='right_login'>
                <div className='right_login_inner'>
                    <h2 className='right_loginhead'>Login</h2>
                    {loginErrorFlag === true &&
                        <label id="loginError" className="inputError"> {loginError} </label>
                    }
                    <div className="form_div">
                        <input
                            type='text'
                            id="username"
                            placeholder="Username"
                            className='form_login'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className="form_div">
                        <input
                            type='password'
                            id="password"
                            placeholder="Password"
                            className='form_login'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button
                        type='submit'
                        className='login_btn'
                        onClick={() => setLoginHandler()}
                    >  Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Login;
